<!-- Header Layout Content -->
<div class="mdk-header-layout__content">

  <div class="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
    <div class="mdk-drawer-layout__content page">
      <div class="container-fluid page__heading-container">
        <div
          class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
          <h1 class="m-lg-0">{{productId ? 'Update Product' : 'Add Product'}} </h1>
          <button mat-stroked-button [routerLink]="['/products']">Back</button>
          <!-- <a href="" class="btn btn-success mr-2" style="margin: 0 auto;"> <i
                          class="material-icons mr-2">save</i>Save</a> -->
        </div>
      </div>
      <mat-card>
        <div class="container-fluid page__container">
          <!--form start-->
          <!-- <h6 class="bold">User details</h6> -->
          <form [formGroup]="createproduct" #f="ngForm" (ngSubmit)="onSubmit(f.value)" novalidate>
            <div formGroupName="product">
              <div class="form-row">
                <div class="form-group col-md-4">
                  <label for="product_category_id">Product Category <span style="color:red">*</span></label>
                  <!-- <input type="number" class="form-control" placeholder="Product Category Id" id="product_category_id" formControlName="product_category_id" [ngModel]="editedProduct?.product_category_id"> -->
                  <select class="form-control" id="product_category_id" style="height: 56% !important;"
                    formControlName="product_category_id" name="product_category_id"
                    [ngModel]="editedProduct?.product_category?.id" (change)="selectCategory($event.target.value)">
                    <option *ngFor="let category of productcategoryList" [value]=category.id>{{category.title}}</option>

                  </select>

                </div>

                <div class="form-group col-md-4" *ngIf="productsubcategoryList.length > 0">

                  <label for="product_sub_category_id">Product sub Category <span style="color:red">*</span></label>
                  <!-- <input type="number" class="form-control" placeholder="Product Category Id" id="product_category_id" formControlName="product_category_id" [ngModel]="editedProduct?.product_category_id"> -->
                  <select class="form-control" id="product_sub_category_id" style="height: 56% !important;"
                    formControlName="product_sub_category_id" name="product_sub_category_id"
                    [ngModel]="editedProduct?.product_sub_category_id"
                    (change)="selectsubCategory($event.target.value)">
                    <option *ngFor="let subcategory of productsubcategoryList" [value]=subcategory.id>
                      {{subcategory.title}}</option>

                  </select>

                </div>

                <div class="form-group col-md-4">
                  <label for="title">Title</label>
                  <input type="text" class="form-control" placeholder="Title" id="title" formControlName="title"
                    [ngModel]="editedProduct?.title">
                </div>
                <div class="form-group col-md-4">
                  <label for="status">Status</label>
                  <select class="form-control" id="status" style="height: 56% !important;" formControlName="status"
                    [ngModel]="editedProduct?.status">
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>
                </div>
                <div class="form-group col-md-8">
                  <label for="description">description <span style="color:red">*</span></label>
                  <input type="text" class="form-control" id="description" placeholder="description"
                    formControlName="description" [ngModel]="editedProduct?.description">

                </div>
                <div class="form-group col-md-8">
                  <table class="table table-bordered" formArrayName="custom_fields">
                    <tr>
                      <th colspan="2">Add Custom Fields:</th>
                      <th width="150px"><button type="button" (click)="addQuantity()" class="btn btn-primary">Add More</button></th>
                    </tr>
                    <tr *ngFor="let quantity of quantities().controls; let i=index" [formGroupName]="i" >
                      <td >
                        label :
                          <input type="text" id="label"formControlName="label" class="form-control" >
                      </td>
                      <td>
                        value:
                          <input type="text" id="value" formControlName="value" class="form-control">
                      </td>
                      <td>
                          <button (click)="removeQuantity(i)" class="btn btn-danger">Remove</button>
                      </td>
                    </tr>
                  </table>
                
               </div>
                <div class="form-group col-md-4">
                  <div class="profile-img">
                    <img *ngIf="!imgURL"
                      src="/assets/images/avatar.png"
                      alt="" />
                    <img *ngIf="imgURL" [src]="imgURL" alt="" />
                    <div class="file btn btn-lg btn-primary">
                      Upload Product
                      <input type="file" name="file" accept="image/*"
                        (change)="uploadProductImage($event,cropeImage)" />
                    </div>
                    <div>
                      <small>Dimensions: 245 x 163</small>
                    </div>
                  </div>
                </div>

              </div>

              <!-- <h6 class="bold">{{employeeId ? null : 'Employee Details'}}</h6> -->
             

            </div>
            <!-- button -->
            <div class="form-group">
              <button type="submit" class="btn btn-primary">Save</button>
              <button type="cancel" class="btn btn-primary ml-2" (click)="cancel()">Cancel</button>
            </div>
          </form>
          <!--form end-->


        </div>
      </mat-card>
    </div>
  </div>
</div>
<!-- // END drawer-layout__content -->

<ng-template #cropeImage let-close="close">
  <div class="modal-header">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 2"
      (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
      (click)="close(); resetSelectedImage(fileInput)">Cancel</button>
    <button type="button" class="btn btn-outline-secondary" (click)="close()">Confirm</button>
  </div>
</ng-template>