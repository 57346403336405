import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AddGuardGuard implements CanActivate {
  userRole:any;

  constructor(private authService:AuthService,private _snackBar: MatSnackBar,private userService:UserService){
    this. userRole = this.authService.getUserRole();
  
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      if(this.userService.rolesArrayPermission.includes("addEmployee") ){
        return true;
       }
       else {
        //   this.userService.setLoader(false);
          this._snackBar.open('You Dont have permission to acccess', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          //this.router.navigateByUrl('/');
          return false;
         }
  }
  
}
