<div class="app-header-inner">
    <div class="container-fluid py-2">
        <div class="app-header-content">
            <div class="row justify-content-between align-items-center" style="height:56px">

                <div class="col-auto">
                    <a id="sidepanel-toggler" class="sidepanel-toggler d-inline-block d-xl-none" href="#">
                        <i class="fa-solid fa-bars"></i>
                    </a>
                </div>
                <!--//col-->
                <div class="col">
                    <a class="nav-link text-white waves-effect" href="javascript:void(0)">
                        <h3 class="text-white">{{companyData}}</h3>
                    </a>
                </div>


                <div class="app-utilities col-auto">

                    <!--//app-utility-item-->
                    <div class="nav-item app-utility-item  dropdown" ngbDropdown placement="bottom-right">
                        <a ngbDropdownToggle class="nav-link    " href="javascript:void(0)">
                            <i class="mdi mdi-message"></i>
                            <div class="notify">
                                 <span class="point"></span>
                            </div>
                        </a>

                        <ul class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>
                            <li class="dropdown-menu-header p-3">
                                <h5 class="dropdown-menu-title mb-0">Notifications</h5>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">
                                    <!-- Message -->
                                    <a href="#" *ngFor="let notification of notifications">
                                        <div class="round {{notification.round}}">
                                            <i class="{{notification.icon}}"></i>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>{{notification.title}}</h5>
                                            <span class="mail-desc">{{notification.subject}}</span>
                                            <span class="time">{{notification.time}}</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);">
                                    <strong>Check all notifications</strong> 
                                </a>
                            </li>
                        </ul>

                    </div>

                    <div class="nav-item app-utility-item dropdown dropdown-menu-content" ngbDropdown
                        placement="bottom-right">
                        <a ngbDropdownToggle class="nav-link  " href="javascript:void(0)">
                            <i class="mdi mdi-email"></i>
                            <div class="notify">
                                 <span class="point"></span>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
                            <li class="dropdown-menu-header p-3">
                                <h5 class="dropdown-menu-title mb-0">Email</h5>
                            </li>
                            <li class="dropdown-menu-header p-3">
                                <div class="drop-title">You have 4 new messages</div>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">
                                    <a href="#" *ngFor="let mymessage of mymessages">
                                        <div class="user-img">
                                            <img src="{{mymessage.useravatar}}" alt="user" class="img-circle"
                                                width="40">
                                            <span class="profile-status {{mymessage.status}} pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>{{mymessage.from}}</h5>
                                            <span class="mail-desc">{{mymessage.subject}}</span>
                                            <span class="time">{{mymessage.time}}</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);">
                                    <strong>See all e-Mails</strong> 
                                </a>
                            </li>
                        </ul>

                    </div>

                    <!-- ============================================================== -->
                    <!-- Profile -->
                    <!-- ============================================================== -->
                    <div class="app-utility-item app-user-dropdown dropdown" ngbDropdown placement="bottom-right">
                        <a ngbDropdownToggle class="dropdown-toggle  " href="javascript:void(0)" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <img src="{{authService.getAvatar()}}" onerror="this.src='../../../assets/images/dummy.png'"
                                alt="user" class="profile-pic" />
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <li>
                                <div class="dw-user-box dropdown-menu-header p-3">
                                    <div class="u-img">
                                        <img src="{{authService.getAvatar()}}"
                                            onerror="this.src='../../../assets/images/dummy.png'" alt="user">
                                    </div>
                                    <div class="u-text">
                                        <h4>{{authService.getUserName()}}</h4>
                                        <p class=" ">{{authService.getUserEmail()}}</p>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a class="dropdown-item" [routerLink]="['/login/profile']">
                                    <i class="ti-user"></i> My Profile</a>
                            </li>

                            <li>
                                <a class="dropdown-item" href="#">
                                    <i class="ti-email"></i> Inbox</a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a class="dropdown-item" href="#">
                                    <i class="ti-settings"></i> Account Setting</a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a class="dropdown-item" style="cursor: pointer" (click)="logout()">
                                    <i class="fa fa-power-off"></i> Logout</a>
                            </li>
                        </ul>

                    </div>
                </div>
                <!--//app-utilities-->
            </div>
            <!--//row-->
        </div>
        <!--//app-header-content-->
    </div>
    <!--//container-fluid-->
</div>
<!--//app-header-inner-->