import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartistModule } from 'ng-chartist';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  imports: [
      FormsModule,
      ReactiveFormsModule,
      CommonModule,
      NgbModule,
      ChartsModule,
      ChartistModule,
      NgxDatatableModule,
      MatIconModule,
      MatMenuModule,
      ImageCropperModule,
      MatTableModule,
      MatFormFieldModule,
      MatPaginatorModule,
      MatInputModule,
      MatSortModule,
      MatDialogModule,
      MatButtonModule,
      MatCardModule,
      MatExpansionModule
  ],
  exports: [
    FormsModule,
      ReactiveFormsModule,
      CommonModule,
      NgbModule,
      ChartsModule,
      ChartistModule,
      NgxDatatableModule,
      MatIconModule,
      MatMenuModule,
      ImageCropperModule,
      MatTableModule,
      MatFormFieldModule,
      MatPaginatorModule,
      MatInputModule,
      MatSortModule,
      MatDialogModule,
      MatButtonModule,
      MatCardModule,
      MatExpansionModule
  ],
  declarations: []

})
export class MaterialComponentModule { }
