import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BannersService {
  baseUrl = environment.baseUrl;
  constructor(private httpRequest: HttpClient) { 
    // this.geturl();
  }

  createBanner = (bannerDetails) => {
    return this.httpRequest.post(this.baseUrl + 'banners', bannerDetails);
  }
  updateBanner = (bannerDetails, bannerId) => {
    return this.httpRequest.patch(this.baseUrl + 'banners/' + bannerId, bannerDetails);
  }
  getBannnersList = () => {
    return this.httpRequest.get(this.baseUrl + 'banners');
  }
  getBanner = (bannerId) => {
    return this.httpRequest.get(this.baseUrl + 'banners/' + bannerId);
  }
}
