import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { CustomerTrnsactionAccount } from '../../services/customerTransactionAccount.service';

import { RequestService } from '../../services/request.service';
import { UserService } from '../../services/user.service';
import * as papa from 'papaparse';

@Component({
    selector: 'app-customerAccountTransac',
    templateUrl: './customerAccountTransactionDetail.html',
    styleUrls: ['./customerAccountTransactionDetails.css']
})
export class customerAccountTransactionDetails implements OnInit {
    transactionList: any[] = [];
    imgURL: any;
    viewBanner: any;
    snackBarDimension: any = {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 5000,
    };
    imageChangedEvent: any;
    fileToReturn: File;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    collection: Array<any> = [];
    dataList: any[];
    bulkUpdate: boolean = false;
    currency: any;
    constructor(public accountTransactionService: CustomerTrnsactionAccount, public userService: UserService, public modalService: NgbModal,
        public _snackBar: MatSnackBar) {
        console.log(this.userService.rolesArrayPermission);
    }

    ngOnInit(): void {
        this.getCustomerAccountList();
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10
        };
        this.currency = localStorage.getItem('currency');
    }

    getCustomerAccountList() {
        this.accountTransactionService.getAccountsList().subscribe((result: any) => {
            console.log(result);
            this.transactionList = result;
            if (!this.bulkUpdate) {
                this.dtTrigger.next();
            }
        })
    }

    viewAccountTransactionDetails(viewBanners, id) {
        this.imgURL = null;
        this.fileToReturn = null;
        this.accountTransactionService.getAccounts(id).subscribe(result => {
            this.viewBanner = result;
            console.log(result);
            if (this.viewBanner && this.viewBanner.asset && this.viewBanner.asset.asset_expiring_url) {
                this.imgURL = this.viewBanner.asset.asset_expiring_url;
            }
            this.modalService.open(viewBanners, { ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true }).result.then((result) => {
            }, (reason) => {
            });
        }), error => {
            this._snackBar.open('Can not get banner details', 'Ok', this.snackBarDimension);
        }
    }

    onFileSelect(event: any, emp): void {
        if (event && event.target.files.length > 0) {
            this.imageChangedEvent = event;
            this.modalService.open(emp, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                // this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    base64ToFile(data, filename) {
        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = window.atob(arr[1]);
        let n = bstr.length;
        // let u8arr = new Uint8Array(n);
        let u8arr = new Uint8Array(new ArrayBuffer(n));
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    imageCropped(event: ImageCroppedEvent) {
        console.log(event);
        if (event.base64) {
            this.fileToReturn = this.base64ToFile(
                event.base64,
                this.imageChangedEvent.target.files[0].name,
            );
            if (this.fileToReturn) {
                const file = this.fileToReturn;
                const reader = new FileReader();
                reader.readAsDataURL(this.fileToReturn);
                reader.onload = (_event) => {
                    this.imgURL = reader.result;
                };
            }
        }
    }

    imageLoaded(event) {
    }
    cropperReady(event) {
    }
    loadImageFailed(event) {
        this.imgURL = this.imgURL;
    }
    resetSelectedImage(element?) {
        this.fileToReturn = null;
        if (this.viewBanner.asset.asset_expiring_url) {
            this.imgURL = this.viewBanner.asset.asset_expiring_url;
        } else {
            this.imgURL = '';
        }
    }

    uploadBannerImage(id) {
        // const formData = new FormData();
        // if (this.fileToReturn) {
        //     formData.append('banner[asset_attributes][asset_file]', this.fileToReturn);
        //     formData.append('banner[asset_attributes][file_name]', this.fileToReturn.name);
        //     this.bannerService.updateBanner(formData, id).subscribe(result => {
        //         this._snackBar.open('Profile updated successfully', 'Ok', this.snackBarDimension);
        //         this.fileToReturn = null;
        //     }), error => {
        //         this._snackBar.open('Failed to upload. Please try again', 'Ok', this.snackBarDimension);
        //     }
        // }
    }

    closeModal = () => {
        this.modalService.dismissAll();
        this.collection = [];
        if (this.bulkUpdate) {
            this.getCustomerAccountList();
        }
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    viewcustomerAccountTransactionCSVmodel(viewCustomerAccountTransactionCSV) {
        this.collection = [];
        this.modalService.open(viewCustomerAccountTransactionCSV, {
            ariaLabelledBy: 'modal-basic-title', size: 'xl',
            scrollable: true
        }).result.then((result) => {

        }, (reason) => {

        });
    }

    fileChangeListenera(files: File[]) {
        this.collection = [];
        if (files[0]) {
            console.log(files[0]);
            papa.parse(files[0], {
                header: true,
                skipEmptylines: true,
                complete: (result, file) => {
                    console.log(result);
                    this.dataList = result.data;

                    for (let x = 0; x < this.dataList.length - 1; x++) {
                        console.log(this.dataList);
                        let customerAccountTransactionObj = {
                            customer_account_transaction: {
                                amount: this.dataList[x].amount,
                                transaction_type: String(this.dataList[x].transaction_type).toLocaleUpperCase(),
                                description: this.dataList[x].description,
                                branch_id: this.dataList[x].branch_id,
                                date: this.dataList[x].date,
                                customer_account_id: this.dataList[x].customer_account_id,
                                transaction_mode: this.dataList[x].transaction_mode
                            }

                        };
                        //  this.callSaveMethod(customerObj);
                        this.collection.push(customerAccountTransactionObj);
                        console.log(this.collection);

                    }

                }
            })
        }
    }

    uploadRowData() {
        for (let i = 0; i <= this.collection.length - 1; i++) {
            this.callSaveMethod(this.collection[i]);
        }
    }



    callSaveMethod(customerTransactionObj) {
        var customerAccountTrId = customerTransactionObj.customer_account_transaction.customer_account_id;
        var index = this.collection.findIndex(item => item.customer_account_transaction.customer_account_id == customerAccountTrId)
        try {
            this.accountTransactionService.bulkCreateCustomerAccountTransaction(customerTransactionObj).subscribe(async result => {
                if (result) {
                    var index = this.collection.findIndex(item => item.customer_account_transaction.customer_account_id == customerAccountTrId)
                    this.collection[index].customer_account_transaction.response = "completed";
                }
            }, async error => {
                this.bulkUpdate = false;
                if (error) {
                    if (error) {
                        var stringMessage = JSON.stringify(error.error);
                        var temp = stringMessage.split(":");
                        var message = JSON.parse(temp[1].replace("}", " ")).toString();
                        this.collection[index].customer_account_transaction.error = message;
                        return;
                    }
                    else {
                        this.collection[index].customer_account_transaction.error = "Failed to update";
                        return;
                    }
                }
            });
        } catch (e) {
            console.log(e);
            this.collection[index].customer_account_transaction.error = "Failed to create";
            this.bulkUpdate = false;;
        }
    }
}

