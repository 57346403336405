import { Component, OnInit, ViewChild ,HostListener} from '@angular/core';
import { BranchesService } from '../../services/branches.service';
import { UserService } from '../../services/user.service';
import { ProductsubcategoryService } from '../../services/productsubcategory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-productCategoryDetail',
  templateUrl: './productsubCategoryDetail.component.html',
  styleUrls: ['./productsubCategoryDetail.component.css']
})
export class ProductsubCategoryComponent implements OnInit {  
  snackBarDimension: any = {
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
    duration: 5000,
};

  viewProductCategory:any;
  elements: any = [];
  headElements = ['ID', 'First', 'Last', 'Handle'];
  searchText: string = '';
  previous: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  productsCategoryId:any;
  productCategoryList: any = [];
    constructor(public productsubCategoryService:ProductsubcategoryService,public userService:UserService,   public _snackBar: MatSnackBar,
      private modalService: NgbModal) {
      console.log(userService.rolesArrayPermission)
    }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    if(this.userService.subcategoryid !=undefined || this.userService.subcategoryid !=null){
    //  this.product_categories/2
    this.productsCategoryId=this.userService.subcategoryid;
      this.getproductCategoryList();
    }
   
  }



  getproductCategoryList(){
    this.productsubCategoryService.getProductSubCategoryList(this.productsCategoryId).subscribe((result: any)=>{
      console.log(result);
     this.productCategoryList = result.sub_categories;
    this.dtTrigger.next();

     })
  }

  viewProductCategoryDetails(viewProductCategory, id){
    this.productsubCategoryService.getProductsubCategory(id).subscribe(result=>{
        this.viewProductCategory = result;
        this.modalService.open(viewProductCategory, {ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true}).result.then((result) => {
        }, (reason) => {
        });
        console.log(this.viewProductCategory);
        
    }), error =>{
        this._snackBar.open('Can not get product sub category details', 'Ok', this.snackBarDimension);
    }
}


closeModal = () =>{
  this.modalService.dismissAll();
}

 
}

