import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { catchError ,retry} from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private baseUrl = environment.baseUrl;

  constructor(private httpRequest: HttpClient) {
   }



   getAccountsList = () => {
    return this.httpRequest.get(this.baseUrl + 'accounts');
  }

  createBanner = (bannerDetails) => {
    return this.httpRequest.post(this.baseUrl + 'accounts', bannerDetails).pipe(retry(2),catchError(this.handleError));
  }


  getAccounts = (bannerId) => {
    return this.httpRequest.get(this.baseUrl + 'accounts/' + bannerId);
  }




  updateAccounts = (bannerDetails, bannerId) => {
    return this.httpRequest.patch(this.baseUrl + 'accounts/' + bannerId, bannerDetails);
  }

  handleError(error: HttpErrorResponse){
    console.log(JSON.stringify(error.error))
    
      return throwError(error);
      }

}