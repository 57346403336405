<!doctype html>
<html lang="en">

<head>
    
</head>


<body>
     <!-- navbar-->
     <header class="header">
        <nav class="navbar navbar-expand-lg fixed-top">
          <div class="container"><a class="navbar-brand" href="#"><img src="assets/home/img/logo.svg" alt="" width="110"></a>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><i class="fas fa-bars"></i></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link link-scroll active" href="#hero">Home <span class="sr-only">(current)</span></a></li>
                <li class="nav-item"><a class="nav-link link-scroll" href="#about">About</a></li>
                <li class="nav-item"><a class="nav-link link-scroll" href="#services">Services</a></li>
                <li class="nav-item"><a class="nav-link link-scroll" href="" [routerLink]="['/login']">Login</a> </li>
                <li class="nav-item"><a class="nav-link link-scroll" href="#contact">Contact us</a></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <!-- Hero Section-->
      <section class="hero bg-top" id="hero" style="background: url(assets/home/img/banner-4.png) no-repeat; background-size: 100% 80%">
        <div class="container">
          <div class="row py-5">
            <div class="col-lg-5 py-5">
              <h1 class="font-weight-bold">Download your best app landing</h1>
              <p class="my-4 text-muted">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod.</p>
              <ul class="list-inline mb-0">
                <li class="list-inline-item mb-2 mb-lg-0"><a class="btn btn-primary btn-lg px-4" href="#"> <i class="fab fa-google-play mr-3"></i>Google Play</a></li>
                <li class="list-inline-item"><a class="btn btn-primary btn-lg px-4" href="#"> <i class="fab fa-app-store mr-3"></i>App Store</a></li>
              </ul>
            </div>
            <div class="col-lg-6 ml-auto">
              <div class="device-wrapper mx-auto">
                <div class="device shadow" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                  <div class="screen"><img class="img-fluid" src="assets/home/img/mobile.png" alt=""></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-center py-0" id="about" style="background: url(assets/home/img/service-bg.svg) no-repeat; background-size: cover">
        <section class="about py-0">
          <div class="container">
            <p class="h6 text-uppercase text-primary">Services</p>
            <h2 class="mb-5">Make your own success</h2>
            <div class="row pb-5">
              <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <!-- Services Item-->
                <div class="card border-0 shadow rounded-lg py-4 text-left">
                  <div class="card-body p-5">
                    <div class="fet-icon">
                      <i class="fas fa-bullseye"></i>
                    </div>
                   
                    <h3 class="font-weight-normal h4 my-4">Online Marketing</h3>
                    <p class="text-small mb-0">Lorem ipsum dolor amet consectetur adipisicing sed do eiusmod tempor incididunt ut labore.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <!-- Services Item-->
                <div class="card border-0 shadow rounded-lg py-4 text-left">
                  <div class="card-body p-5">
                    <div class="fet-icon">
                      <i class="fas fa-bullseye"></i>
                    </div>
                    <h3 class="font-weight-normal h4 my-4">Track your move </h3>
                    <p class="text-small mb-0">Lorem ipsum dolor amet consectetur adipisicing sed do eiusmod tempor incididunt ut labore.</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <!-- Services Item-->
                <div class="card border-0 shadow rounded-lg py-4 text-left">
                  <div class="card-body p-5">
                    <div class="fet-icon">
                      <i class="fas fa-bullseye"></i>
                    </div>
                    <h3 class="font-weight-normal h4 my-4">Market analysis</h3>
                    <p class="text-small mb-0">Lorem ipsum dolor amet consectetur adipisicing sed do eiusmod tempor incididunt ut labore.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="with-pattern-1" id="services">
          <div class="container">
            <div class="row align-items-center mb-5">
              <div class="col-lg-6 mb-5 mb-lg-0"><img class="img-fluid w-100 px-lg-5" src="assets/home/img/objects.svg" alt=""></div>
              <div class="col-lg-6">
                <h2>ABOUT US</h2>
                <p class="text-muted">We intend to revolutionise Cooperative Credit Entities by giving them a singular platform to connect with their customers, offering solutions that make their existing procedures more efficient and enabling them to further create significant social and economic impact for the under-banked and lower-income group community.</p>
               </div>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-6 mb-5 mb-lg-0">
                <h2>Our Goals</h2>
                <!-- p class="text-muted">Dolor sit amet consectetur elit sed do eiusmod tempor incididunt labore dolore magna aliqua enim ad minim veniam quis nostrud exercitation.</p> -->
                <ul class="list-check">
                  <li class="text-muted mb-2">Increase financial literacy</li>
                  <li class="text-muted mb-2">Champion initiatives like Bharat Inclusion, Cashless economy, and e-KYC</li>
                  <li class="text-muted mb-2">Provide easy access to a variety of financial services to the cooperative community at large</li>
                </ul>
                <!-- <button class="btn btn-primary js-modal-btn" data-video-id="B6uuIHpFkuo"><i class="fas fa-play-circle mr-2"></i>Play video</button> -->
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6 col-sm-6 mb-4">
                    <!-- Services Item-->
                    <div class="card border-0 shadow rounded-lg text-left px-2">
                      <div class="card-body px py-5">
                        <div class="fet-icon">
                          <i class="fas fa-bullseye"></i>
                        </div>
                        <h3 class="h5 font-weight-normal h4 my-3">Online Marketing</h3>
                        <p class="text-small mb-0 text-muted">Lorem ipsum dolor amet consectetur adipisicing.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 mb-4">
                    <!-- Services Item-->
                    <div class="card border-0 shadow rounded-lg text-left px-2">
                      <div class="card-body px py-5">
                        <div class="fet-icon">
                          <i class="fas fa-bullseye"></i>
                        </div>
                        <h3 class="h5 font-weight-normal h4 my-3">Track your move </h3>
                        <p class="text-small mb-0 text-muted">Lorem ipsum dolor amet consectetur adipisicing.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6 mb-4">
                    <!-- Services Item-->
                    <div class="card border-0 shadow rounded-lg text-left px-2">
                      <div class="card-body px py-5">
                        <div class="fet-icon">
                          <i class="fas fa-bullseye"></i>
                        </div>
                        <h3 class="h5 font-weight-normal h4 my-3">Market analysis</h3>
                        <p class="text-small mb-0 text-muted">Lorem ipsum dolor amet consectetur adipisicing.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <!-- Services Item-->
                    <div class="card border-0 shadow rounded-lg text-left px-2">
                      <div class="card-body px py-5">
                        <div class="fet-icon">
                          <i class="fas fa-bullseye"></i>
                        </div>
                        <h3 class="h5 font-weight-normal h4 my-3">Full Settings</h3>
                        <p class="text-small mb-0 text-muted">Lorem ipsum dolor amet consectetur adipisicing.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row align-items-center mb-5">
              <div class="col-lg-6 mb-5 mb-lg-0"><img class="img-fluid w-100 px-lg-5" src="assets/home/img/objects.svg" alt=""></div>
              <div class="col-lg-6">
                <h2>How to Get there?</h2>
                <p class="text-muted">We would be providing a one-stop solution to our customers by using cutting edge technologies, agile and brave innovation culture while transforming the legacy systems, so as to help them manage their funds more productively, including how they spend, transfer, invest, borrow, or save.</p>
                
              </div>
            </div>
          </div>
        </section>
      </section>
      <section class="bottom-logos">
        <div class="container">
          <div class="row">
            <div class="col-md-3"><img src="assets/home/img/img1.png" class="img-fluid"></div>
            <div class="col-md-3"><img src="assets/home/img/img2.png" class="img-fluid"></div>
            <div class="col-md-3"><img src="assets/home/img/img3.png" class="img-fluid"></div>
            <div class="col-md-3"><img src="assets/home/img/img4.png" class="img-fluid"></div>
          </div>
        </div>
      </section>
      <section class="p-0" id="testimonials" style="background: url(assets/home/img/testimonials-bg.png) no-repeat; background-size: 40% 100%; background-position: left center">
        <div class="container text-center">
          <p class="h6 text-uppercase text-primary">Testimonials</p>
          <h2 class="mb-5">What Our Clients Says?</h2>
          <div class="row">
            <div class="col-lg-8 mx-auto">
              <div class="owl-carousel owl-theme testimonials-slider">
                <div class="mx-3 mx-lg-5 my-5 pt-3">
                  <div class="card shadow rounded-lg px-4 py-5 px-lg-5 with-pattern bg-white border-0">
                    <div class="card-body index-forward pt-5 rounded-lg">
                      <div class="testimonial-img"><img class="rounded-circle" src="assets/home/img/avatar-1.jpg" alt=""/></div>
                      <p class="lead text-muted mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                      <h5 class="font-weight-bold mb-0">Sarah Hudson</h5>
                      <p class="text-primary mb-0 text-small">Tech Developer</p>
                    </div>
                  </div>
                </div>
                <div class="mx-3 mx-lg-5 my-5 pt-3">
                  <div class="card shadow rounded-lg px-4 py-5 px-lg-5 with-pattern bg-white border-0">
                    <div class="card-body index-forward pt-5 rounded-lg">
                      <div class="testimonial-img"><img class="rounded-circle" src="assets/home/img/avatar-2.png" alt=""/></div>
                      <p class="lead text-muted mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                      <h5 class="font-weight-bold mb-0">Frank Smith</h5>
                      <p class="text-primary mb-0 text-small">Tech Developer</p>
                    </div>
                  </div>
                </div>
                <div class="mx-3 mx-lg-5 my-5 pt-3">
                  <div class="card shadow rounded-lg px-4 py-5 px-lg-5 with-pattern bg-white border-0">
                    <div class="card-body index-forward pt-5 rounded-lg">
                      <div class="testimonial-img"><img class="rounded-circle" src="assets/home/img/avatar-1.jpg" alt=""/></div>
                      <p class="lead text-muted mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                      <h5 class="font-weight-bold mb-0">Sarah Hudson</h5>
                      <p class="text-primary mb-0 text-small">Tech Developer</p>
                    </div>
                  </div>
                </div>
                <div class="mx-3 mx-lg-5 my-5 pt-3">
                  <div class="card shadow rounded-lg px-4 py-5 px-lg-5 with-pattern bg-white border-0">
                    <div class="card-body index-forward pt-5 rounded-lg">
                      <div class="testimonial-img"><img class="rounded-circle" src="assets/home/img/avatar-2.png" alt=""/></div>
                      <p class="lead text-muted mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                      <h5 class="font-weight-bold mb-0">Frank Smith</h5>
                      <p class="text-primary mb-0 text-small">Tech Developer</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section><a class="scropll-top-btn" id="scrollTop" href="#"><i class="fas fa-long-arrow-alt-up"></i></a>
      <footer class="with-pattern-1 position-relative">
        <div class="container section-padding-y">
          <div class="row">
            <div class="col-lg-3 mb-4 mb-lg-0"><img class="mb-4" src="assets/home/img/logo.svg" alt="" width="110">
              <p class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
            </div>
            <div class="col-lg-2 mb-4 mb-lg-0">
              <h2 class="h5 mb-4">Quick Links</h2>
              <div class="d-flex">
                <ul class="list-unstyled d-inline-block mr-4 mb-0">
                  <li class="mb-2"><a class="footer-link" href="#">History </a></li>
                  <li class="mb-2"><a class="footer-link" href="#">About us</a></li>
                  <li class="mb-2"><a class="footer-link" href="#">Contact us</a></li>
                  <li class="mb-2"><a class="footer-link" href="#">Services</a></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 mb-4 mb-lg-0">
              <h2 class="h5 mb-4">Services</h2>
              <div class="d-flex">
                <ul class="list-unstyled mr-4 mb-0">
                  <li class="mb-2"><a class="footer-link" href="#">History </a></li>
                  <li class="mb-2"><a class="footer-link" href="#">About us</a></li>
                  <li class="mb-2"><a class="footer-link" href="#">Contact us</a></li>
                  <li class="mb-2"><a class="footer-link" href="#">Services</a></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-5">
              <h2 class="h5 mb-4">Contact Info</h2>
              <ul class="list-unstyled mr-4 mb-3">
                <li class="mb-2 text-muted">Nagpur. </li>
                <li class="mb-2"><a class="footer-link" href="tel:7722084040">7722084040</a></li>
                <li class="mb-2"><a class="footer-link" href="mailto:hello@coderkeys.com">hello@coderkeys.com</a></li>
              </ul>
              <ul class="list-inline mb-0">
                <li class="list-inline-item"><a class="social-link" target="_blank" href="https://www.facebook.com/#/"><i class="fab fa-facebook-f"></i></a></li>
                <li class="list-inline-item"><a class="social-link" target="_blank" href="https://twitter.com/#"><i class="fab fa-twitter"></i></a></li>
                <li class="list-inline-item"><a class="social-link" target="_blank" href="https://www.youtube.com/"><i class="fab fa-youtube"></i></a></li>
                <li class="list-inline-item"><a class="social-link" target="_blank" href="https://in.pinterest.com/#/"><i class="fab fa-pinterest"></i></a></li>
                <li class="list-inline-item"><a class="social-link" target="_blank" href="https://www.linkedin.com/company/#/"><i class="fab fa-linkedin"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="copyrights">       
          <div class="container text-center py-4">
            <p class="mb-0 text-muted text-sm">&copy; 2022, CBK. Develop by <a href="https://coderkeys.com" class="text-reset">Coderkeys</a>.</p>
          </div>
        </div>
      </footer>
    <!-- footer part end-->
 </body>

</html>