<div class="mdk-drawer-layout__content page">
    <div class="container-fluid page__heading-container">
        <div class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
            <h1 class="m-lg-0">Accounts Balances</h1>
            <!--   -->
            <div class="row">
                <div class=" col-md-6 ">
                    <button style="width: max-content" class="btn btn-primary " *ngIf="userService.rolesArrayPermission.includes(
            'addCustomerAccounts') " (click)=" viewcustomerAccountBalancesCSVmodel(viewCustomerAccountBalanceCSV) ">
                        <i class="material-icons mr-2 ">add_circle</i>
                        Customer Balance file</button>
                </div>
                <div class=" col-md-6 ">
                    <button style="width: max-content;float: right; " class="btn btn-primary" *ngIf="userService.rolesArrayPermission.includes('addCustomerAccounts')"
                        [routerLink]="['/customeraccountsbalance/addcustomerAccountbalance']">
                        <i class="material-icons mr-2">add_circle</i>Add New</button>
                </div>
            </div>
        </div>
        <!-- [routerLink]="['/banner/addBanner', data?.id]" -->
        <!-- <button (routerLink]="[/MultiAccountUploadComponent]">MultiAccountUploadComponent</button> -->
    </div>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                    <thead>
                        <tr style="text-align: center;">
                            <th width="50px">#</th>
                            <th width="50px">Account Holder</th>
                            <th width="50px">Account Type</th>
                            <th width="50px">Date</th>
                            <th width="120">Amount</th>
                            <th width="50px">Description</th>

                            <th width="150">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="bannersList && bannersList.length > 0">
                        <tr *ngFor="let banner of bannersList; let index = index" style="text-align: center;">
                            <td>{{index + 1}}</td>
                            <td>{{banner?.customer?.name}}</td>
                            <td>{{banner?.account?.name}}</td>
                            <td>{{banner?.date|date:'dd/MM/yyyy'}}</td>
                            <td class="capitalize">{{banner?.amount | currency:currency}}</td>
                            <td>{{banner?.description}}</td>

                            <td>
                                <button class="btn bg-transparent" *ngIf="userService.rolesArrayPermission.includes('editAccounts')" [routerLink]="['/customeraccountsbalance/addcustomerAccountbalance', banner?.id]">
                                    <a class="edit" title="Edit" data-toggle="tooltip">
                                        <i class="material-icons">&#xE254;</i>
                                    </a>
                                </button>
                                <button class="btn bg-transparent">
                                    <a class="view" title="view" data-toggle="tooltip" (click)="viewBannerDetails(viewBanners, banner?.id)">
                                        <i class="material-icons">visibility</i>
                                    </a>
                                </button>

                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- <div class="text-center wd-100" *ngIf="bannersList && bannersList.length == 0">
        No records found
    </div> -->
</div>

<ng-template #viewBanners let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="container emp-profile">
                <form method="post">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="float-right" style="width: 80px;">
                                <input type="submit" *ngIf="userService.rolesArrayPermission.includes('editCustomerAccounts')" [routerLink]="['/customeraccounts/addcustomerAccounts', viewBanner?.id]"
                                    class="profile-edit-btn" (click)="closeModal()" name="btnAddMore" value="Edit" />
                            </div>
                            <div class="row">
                                <div class="col-md-4"></div>
                                <div *ngIf="fileToReturn" class="mt-2 text-center pointer col-md-4">
                                    <button (click)="uploadBannerImage(viewBanner?.id)">Upload</button>
                                </div>
                                <div class="col-md-4"></div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <!-- <div class="float-right" style="width: 80px;">
                                <input type="submit" *ngIf="userService.rolesArrayPermission.includes('addBanners')"
                                    [routerLink]="['/banners/addBanner', viewBanner?.id]" class="profile-edit-btn"
                                    (click)="closeModal()" name="btnAddMore" value="Edit" />
                            </div> -->
                            <div class="profile-head">
                                <h5>
                                    {{viewBanner?.nuumber}}
                                </h5>
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">About</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content profile-tab" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="row" *ngIf="viewBanner?.number">
                                        <div class="col-md-4">
                                            <label>Number</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewBanner?.number}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewBanner?.company">
                                        <div class="col-md-4">
                                            <label>company</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewBanner?.company?.name}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewBanner?.status">
                                        <div class="col-md-4">
                                            <label>Status</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p class="capitalize">{{viewBanner?.status?.toLowerCase()}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewBanner?.description">
                                        <div class="col-md-4">
                                            <label>Description</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewBanner?.description}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewBanner?.customer">
                                        <div class="col-md-4">
                                            <label>Customer Name</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewBanner?.customer?.name}}</p>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <button (click)="closeModal()" class="btn btn-primary float-right">OK</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #cropeImage let-close="close">
    <div class="modal-header">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 2" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="close(); resetSelectedImage(fileInput)">Cancel</button>
        <button type="button" class="btn btn-outline-secondary" (click)="close()">Confirm</button>
    </div>
</ng-template>

<ng-template #viewCustomerAccountBalanceCSV let-model style="width: 813px !important;
height: 600px !important;">
    <div class="container " data-backdrop="static" data-keyboard="false">
        <button (click)="closeModal()" class="btn btn-primary float-right ">close</button>
        <div class="row custome Padding">

            <div class="col-md-12">

                <p>you can upload on excel file to create multiple customer accounts at once.</p>
                <div>
                    <span>Sample Excel file:</span>

                </div>
                <a href="./assets/files/customerAccountBalance.csv" download> customer_account_balance_upload_templates.xls</a>
            </div>

            <div class="col-md-12
            customePadding">
                <p>Select Spreadsheet file :</p>

                <input type="file" name="files" class="form-control" #uploads (change)="fileChangeListenera(uploads.files)" multiple value="process"
                    accept=".csv, .xls, .xlsx" />
                <!-- (change)="fileChangeListenera(uploads.files)" -->
            </div>
            <div class="col-md-12" *ngIf="collection.length>0">
                <div class=" col-md-12 text-right ">
                    <button class="btn btn-primary " (click)="uploadRowData() ">System Data import </button>
                </div>

            </div>
            <div class="col-md-12" *ngIf="collection.length>0" style="max-height:600px;overflow:auto !important">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Customer Account ID</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Description</th>
                            <th scope="col">Date</th>
                            <th scope="col">Response</th>
                            <th scope="col">Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of collection ;index as i">
                            <td>{{item.customer_account_balance.customer_account_id ? i+1 : null}}</td>
                            <td>{{item.customer_account_balance.customer_account_id}}</td>
                            <td>{{item.customer_account_balance.amount}}</td>
                            <td>{{item.customer_account_balance.description}}</td>
                            <td>{{item.customer_account_balance.date}}</td>
                            <td>{{item.customer_account_balance.response}}</td>
                            <td>{{item.customer_account_balance.error}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>