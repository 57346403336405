import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../services/user.service';


@Injectable({
  providedIn: 'root'
})
export class BlogGuardGuard implements CanActivate {
  constructor(private _snackBar: MatSnackBar, private userService: UserService) {}

  canActivate() {
    if (this.userService.rolesArrayPermission.includes("addBlog")) {
      return true;
    }else {
      this._snackBar.open('You Dont have permission to acccess', 'Ok', {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 5000,
      });
      return false;
    }
  }
}

