import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CommonModule,
    LocationStrategy,
    HashLocationStrategy
} from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthInterceptor } from './interceptor/auth-interceptor';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//import {AddKnowlegeBaseGuard} from './knowledge-base/add-knowlege-base.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UsableUIComponent } from './usable-ui/usable-ui.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HomeComponent } from './home/home.component';
import { HomeModule } from './home/home.module';
import { RoleComponent } from './role/role-details/role.component';
import { PartnerComponent } from './partner/partner-details/partner.component';
import { ProductCategoryComponent } from './productCategory/productCategory-details/productCategoryDetail.component';
import { ProductsubCategoryComponent } from './productsubCategory/productsubCategory-details/productsubCategoryDetail.component';
import { PromotionalComponent } from './promotional/promotional-details/promotional.component';
import { BannersComponent } from './banners/banners-details/banners.component';
import { KnowlegebaseComponent } from './knowlegebase/knowlegebase-details/knowlegebase.component';
import { SocialMediaComponent } from './social-media/socialMedia-details/social-media.component';
import { ComplaintComponent } from './complaint/complaint-details/complaint.component';
import { SettingComponent } from './setting/setting-details/setting.component';
import { SupportComponent } from './support/support-details/support.component';
import { MaterialComponentModule } from './shared/material-components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BranchGuardGuard } from './branches/branch-guard.guard';
import { EmployeeGuardGuard } from './employee/employee-guard.guard';
import { CustomerGuard } from './customer/customer-guard.guard';
import { DataTablesModule } from 'angular-datatables';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { AddGuardGuard } from './employee/add-guard.guard';
import { AddpartnarguardGuard } from './partner/addpartnarguard.guard';
import { EditbannerguardGuard } from './banners/editbannerguard.guard';
import { BannerguardGuard } from './banners/bannerguard.guard';
import { PartnerGardGuard } from './partner/partner-gard.guard';

import { AddbranchGuardGuard } from './branches/addbranch-guard.guard';
import { AddSocialGuardGuard } from './social-media/add-social-guard.guard';
import { EditSocialGuardGuard } from './social-media/edit-social-guard.guard';
import { ProfileEditGuard } from './dashboards/dashboard-components/profile/profile-edit.guard';
import { ProfileAddGuard } from './dashboards/dashboard-components/profile/profile-add.guard';
import { AddCustomerGuard } from './customer/add-customer.guard';
import { KnowlegebaseGuard } from './knowlegebase/knowlegebaseguard.guard';
import { A11yModule } from '@angular/cdk/a11y';
import { ProductDetailsComponent } from './product/product-details/product-details.component';
import { CreateProductComponent } from './product/create-product/create-product.component';
import { BlogListComponent } from './blogs/blog-list/blog-list.component';
import { AddBlogsComponent } from './blogs/add-blogs/add-blogs.component';
import { EditblogGuardGuard } from './blogs/editblog-guard.guard';
import { BlogGuardGuard } from './blogs/blog-guard.guard';
import { QuillModule } from 'ngx-quill';
import { RequestComponent } from './request/request-details/request.component';
import { AccountsComponent } from './accounts/accounts-details/accounts.component';
import { customerAccountsComponent } from './customerAccount/customeraccounts-details/customeraccounts.component';
import { MultiAccountUploadComponent } from './multi-account-upload/multi-account-upload.component';
import { customerAccountsbalanceComponent } from './customerAccountBalance/customeraccountsbalance-details/customeraccountsbalance.component';
import { customerAccountTransactionDetails } from './customerAccountTransaction/customerAccountTransaction-details/customerAccountTransactionDetails';
import { ErrorComponentComponent } from './error-component/error-component.component';
import { DatePipe } from '@angular/common'
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        BlankComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SidebarComponent,
        UsableUIComponent,
        HomeComponent,
        RoleComponent,
        PartnerComponent,
        KnowlegebaseComponent,
        ProductCategoryComponent, ProductsubCategoryComponent, customerAccountTransactionDetails,
        PromotionalComponent,
        BannersComponent, RequestComponent, AccountsComponent, customerAccountsComponent, customerAccountsbalanceComponent,
        SocialMediaComponent, CreateProductComponent,
        ComplaintComponent,
        SettingComponent,
        SupportComponent,
        BlogListComponent,
        AddBlogsComponent,
        MultiAccountUploadComponent,
        ErrorComponentComponent


    ],
    imports: [
        CommonModule,
        BrowserModule,
        A11yModule,
        DataTablesModule,
        FormsModule,

        ReactiveFormsModule,
        BrowserAnimationsModule, NgxCsvParserModule,
        HttpClientModule,
        NgbModule,
        ToastrModule.forRoot(),

        NgMultiSelectDropDownModule.forRoot(),
        QuillModule.forRoot(),
        PerfectScrollbarModule,
        AppRoutingModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        ImageCropperModule,
        HomeModule,
        RouterModule,
        MaterialComponentModule,

    ],

    providers: [CustomerGuard, AddCustomerGuard, BranchGuardGuard, EmployeeGuardGuard, AddGuardGuard, AddpartnarguardGuard, PartnerGardGuard, AddbranchGuardGuard, ProfileAddGuard,
        KnowlegebaseGuard, EditbannerguardGuard, BannerguardGuard, EditSocialGuardGuard, AddSocialGuardGuard, ProfileEditGuard, EditblogGuardGuard, BlogGuardGuard,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }, DatePipe],
    bootstrap: [AppComponent]
})
export class AppModule { }
