<div class="mdk-drawer-layout__content page">
  <div class="container-fluid page__heading-container">
    <div
      class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
      <h1 class="m-lg-0">Knowlegebase</h1>
      <button class="btn btn-primary ml-lg-3 float-right"  
      *ngIf="userService.rolesArrayPermission.includes('addKnowledgeBase')"
      [routerLink]="['/knowledgeBase/addKnowlegebase']"><i
          class="material-icons mr-2">add_circle</i>Add New</button>
    </div>
    <!-- [routerLink]="['/banner/addBanner', data?.id]" 
   
    -->
  </div>


  <div class="mat-elevation-z8">
    <table class="table table-hover thead-light">
      <thead>
        <tr>
          <th width="50px">#</th>
          <th >Title</th>
       
          <th>Description</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let banner of bannersList; let index = index">
          <td>{{index + 1}}</td>
          <td>{{banner?.title}}</td>
          <td>{{banner?.description}}</td>
          <td class="capitalize">{{banner?.status?.toLowerCase()}}</td>
          <td>
            <button class="btn bg-transparent" 
            *ngIf="userService.rolesArrayPermission.includes('addBanners')"
            [routerLink]="['/knowledgeBase/addKnowlegebase', banner?.id]">
              <a class="edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a>
            </button>
            <button class="btn bg-transparent">
              <a class="view" title="view" data-toggle="tooltip"><i class="material-icons">visibility</i></a>
            </button>

          </td>

        </tr>
      </tbody>

    </table>
  </div>
</div>



<ng-template #cropeImage let-close="close">
  <div class="modal-header">
      <image-cropper [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true" [aspectRatio]="4 / 2"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary"
          (click)="close(); resetSelectedImage(fileInput)">Cancel</button>
      <button type="button" class="btn btn-outline-secondary"
          (click)="close()">Confirm</button>
  </div>
</ng-template>