import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { catchError ,retry} from 'rxjs/operators'
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  baseUrl = environment.baseUrl;
  
  partnerUrl:any;
 
  constructor(public  _snackBar: MatSnackBar,private httpClient: HttpClient) { 
    this.geturl();
  }
  geturl() {
    let url = this.baseUrl ;
    console.log(url)
    return this.httpClient.get<JSON>(url);
  }
  
    
  getPartnerList = () => {
    return this.httpClient.get(this.baseUrl + 'companies').pipe(retry(2),
      catchError(this.handleError)
      );

  }
 

  addPartnerLDetails = (PartnerData) => {
    console.log(PartnerData+"service section")
    return this.httpClient.post(this.baseUrl + 'companies', PartnerData).pipe(retry(2),catchError(this.handleError))
   
  }
  getPartner=(id)=>{
    console.log(JSON.stringify(id))
    return this.httpClient.get(this.baseUrl + 'companies/'+id)

    // return this.httpClient.get()

  }
  getPartnerUpdate(id,PartnerData){
    return this.httpClient.patch(this.baseUrl+'companies/'+id,PartnerData)
  }



  // getEmployeesList() {
//   this.userService.setLoader(true);
//   this.employeesService.getemployeesList().subscribe(employeeList => {
//     this.employeeList = employeeList;
//     console.log(this.employeeList)
//     this.userService.setLoader(false);
//   },
//     error => {
//       this.userService.setLoader(false);
//       this._snackBar.open('Employee not found', 'Ok', {
//         horizontalPosition: 'end',
//         verticalPosition: 'bottom',
//         duration: 5000,
//       });
//     });
// }

  deletePartner = (id) => {
    return this.httpClient.delete(this.baseUrl + 'companies/' + id).pipe(retry(2),catchError(this.handleError));
  }

  switchToOtherBank(cooperateId){
    var switchObj={"company_id":cooperateId};
    console.log(switchObj);
    return this.httpClient.post(this.baseUrl+'users/switch_company',switchObj).pipe(retry(2),catchError(this.handleError))
   // users/switch_company
  }

  handleError(error: HttpErrorResponse){
  console.log(JSON.stringify(error.error))
  
    return throwError(error);
    }
}