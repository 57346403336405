import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { BannersService } from '../../services/banners.service';
import { UserService } from '../../services/user.service';
import { AccountsService } from '../../services/accounts.service';
import { customerAccountsbalanceService } from '../../services/customeraccountsbalance.service ';
import * as papa from 'papaparse';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'

@Component({
    selector: 'app-customeraccountbalance',
    templateUrl: './customeraccountsbalance.component.html',
    styleUrls: ['./customeraccountsbalance.component.css']
})
export class customerAccountsbalanceComponent implements OnInit {
    bannersList: any[] = [];
    imgURL: any;
    viewBanner: any;
    snackBarDimension: any = {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 5000,
    };
    imageChangedEvent: any;
    fileToReturn: File;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    collection: Array<any> = [];
    dataList: any[];
    bulkUpdate: boolean = false;
    today: any;
    latest_date: any;
    currency: any;
    constructor(public customerBalanceService: customerAccountsbalanceService, public userService: UserService, public modalService: NgbModal,
        public datepipe: DatePipe,
        public _snackBar: MatSnackBar) {
        console.log(this.userService.rolesArrayPermission);
        this.today = (new Date().toISOString().slice(0, 10)).toString();
        //this.latest_date = this.datepipe.transform(this.today, 'dd-MM-yyyy')
    }

    ngOnInit(): void {
        this.dtTrigger.next();
        this.getCustomerBalanceList();
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10
        };
        this.currency = localStorage.getItem('currency');
    }

    getCustomerBalanceList() {
        this.customerBalanceService.getcustomerAccountsList().subscribe((result: any) => {
            console.log(result);
            this.bannersList = result;
            if (!this.bulkUpdate) {
                this.dtTrigger.next();
            }
        })
    }

    viewBannerDetails(viewB, id) {
        this.imgURL = null;
        this.fileToReturn = null;
        this.customerBalanceService.getcustomerAccounts(id).subscribe(result => {
            this.viewBanner = result;
            console.log(result);
            if (this.viewBanner && this.viewBanner.asset && this.viewBanner.asset.asset_expiring_url) {
                this.imgURL = this.viewBanner.asset.asset_expiring_url;
            }
            this.modalService.open(viewB, { ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true }).result.then((result) => {
            }, (reason) => {
            });
        }), error => {
            this._snackBar.open('Can not get banner details', 'Ok', this.snackBarDimension);
        }
    }

    onFileSelect(event: any, emp): void {
        if (event && event.target.files.length > 0) {
            this.imageChangedEvent = event;
            this.modalService.open(emp, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                // this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    base64ToFile(data, filename) {
        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = window.atob(arr[1]);
        let n = bstr.length;
        // let u8arr = new Uint8Array(n);
        let u8arr = new Uint8Array(new ArrayBuffer(n));
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    imageCropped(event: ImageCroppedEvent) {
        console.log(event);
        if (event.base64) {
            this.fileToReturn = this.base64ToFile(
                event.base64,
                this.imageChangedEvent.target.files[0].name,
            );
            if (this.fileToReturn) {
                const file = this.fileToReturn;
                const reader = new FileReader();
                reader.readAsDataURL(this.fileToReturn);
                reader.onload = (_event) => {
                    this.imgURL = reader.result;
                };
            }
        }
    }

    imageLoaded(event) {
    }
    cropperReady(event) {
    }
    loadImageFailed(event) {
        this.imgURL = this.imgURL;
    }
    resetSelectedImage(element?) {
        this.fileToReturn = null;
        if (this.viewBanner.asset.asset_expiring_url) {
            this.imgURL = this.viewBanner.asset.asset_expiring_url;
        } else {
            this.imgURL = '';
        }
    }



    closeModal = () => {
        this.modalService.dismissAll();
        this.collection = [];
        if (this.bulkUpdate) {
            this.getCustomerBalanceList();
        }
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    viewcustomerAccountBalancesCSVmodel(viewCustomerAccountBalanceCSV) {
        this.collection = [];
        this.modalService.open(viewCustomerAccountBalanceCSV, {
            ariaLabelledBy: 'modal-basic-title', size: 'xl',
            scrollable: true
        }).result.then((result) => {

        }, (reason) => {

        });
    }

    fileChangeListenera(files: File[]) {
        this.collection = [];
        if (files[0]) {
            console.log(files[0]);
            papa.parse(files[0], {
                header: true,
                skipEmptylines: true,
                complete: (result, file) => {
                    console.log(result);
                    this.dataList = result.data;

                    for (let x = 0; x < this.dataList.length - 1; x++) {
                        console.log(this.dataList);
                        let customerAccountBalanceObj = {
                            customer_account_balance: {
                                amount: Number(this.dataList[x].amount),
                                description: this.dataList[x].description,
                                date: this.dataList[x].date,
                                customer_account_id: Number(this.dataList[x].customer_account_id)
                            }

                        };
                        //  this.callSaveMethod(customerObj);
                        this.collection.push(customerAccountBalanceObj);
                        console.log(this.collection);

                    }

                }
            })
        }
    }

    uploadRowData() {
        for (let i = 0; i <= this.collection.length - 1; i++) {
            this.callSaveMethod(this.collection[i]);
        }
    }

    callSaveMethod(customerBalanceObj) {
        try {
            const formData = new FormData();
            formData.append('customer_account_balance[amount]', customerBalanceObj.customer_account_balance.amount);
            formData.append('customer_account_balance[description]', customerBalanceObj.customer_account_balance.description);
            formData.append('customer_account_balance[date]', customerBalanceObj.customer_account_balance.date);
            formData.append('customer_account_balance[customer_account_id]', customerBalanceObj.customer_account_balance.customer_account_id);

            var customerAccountId = customerBalanceObj.customer_account_balance.customer_account_id;
            var index = this.collection.findIndex(item => item.customer_account_balance.customer_account_id == customerAccountId)
            var balanceDate = new Date(customerBalanceObj.customer_account_balance.date);
            var balanceDay = balanceDate.getDate();
            var balanceMonth = balanceDate.getMonth() + 1;
            var balanceYear = balanceDate.getFullYear();
            var currentDay = new Date().getDate();
            var currentMontth = new Date().getMonth() + 1;
            var currentYear = new Date().getFullYear();

            if (balanceYear > currentYear) {
                this.collection[index].customer_account_balance.error = "Date is greater than today's date ";
                return;
            }
            else if (balanceMonth > currentMontth) {
                this.collection[index].customer_account_balance.error = "Date is greater than today's date ";
                return;
            }
            else if (balanceDay > currentDay) {
                this.collection[index].customer_account_balance.error = "Date is greater than today's date ";
                return;
            }
            this.customerBalanceService.bulkCreatecustomerBalance(formData).subscribe(async result => {
                if (result) {
                    this.collection[index].customer_account_balance.response = "completed";
                }
            }, async error => {
                this.bulkUpdate = false;
                if (error) {
                    if (error) {
                        var stringMessage = JSON.stringify(error.error);
                        var temp = stringMessage.split(":");
                        var message = JSON.parse(temp[1].replace("}", " ")).toString();
                        this.collection[index].customer_account_balance.error = message;
                        return;
                    }
                    else {
                        this.collection[index].customer_account_balance.error = "Failed to update";
                        return;
                    }
                }
            });
        } catch (e) {
            console.log(e);
            this.bulkUpdate = false;;
        }
    }
}

