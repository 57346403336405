import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class EditbannerguardGuard implements CanActivate {
  constructor(private _snackBar: MatSnackBar,private router: Router,private userService:UserService){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
       if(this.userService.rolesArrayPermission.includes("editBanners")){
        return true;
       }
        
   
   else {
 
       this._snackBar.open('You Dont have permission to acccess', 'Ok', {
         horizontalPosition: 'end',
         verticalPosition: 'bottom',
         duration: 5000,
       });
     
       return false;
      }
  }
  
}
