<div class="mdk-drawer-layout__content page">
    <div class="container-fluid page__heading-container">
        <div
            class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
            <h1 class="m-lg-0">Account Types</h1>
            <button class="btn btn-primary ml-lg-3 float-right"
                *ngIf="userService.rolesArrayPermission.includes('addAccounts')" [routerLink]="['/accounts/addAccounts']"><i
                    class="material-icons mr-2">add_circle</i>Add New</button>
        </div>
        <!-- [routerLink]="['/banner/addBanner', data?.id]" -->
    </div>
    <div class="container-fluid">
        <div class="card">
            <div class="card-body">
                <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                    <thead>
                        <tr>
                            <th width="50px">#</th>
                            <th width="50px">Account Type ID</th>
                            <th width="50px">Name</th>
                            <th width="50px">Description</th>  
                            <th width="120">Status</th>
                            <th width="150">Action</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="bannersList && bannersList.length > 0">
                        <tr *ngFor="let banner of bannersList; let index = index">
                            <td>{{index + 1}}</td>
                            <td>{{banner?.id}}</td>
                            <td>{{banner?.name}}</td>
                            <td>{{banner?.description}}</td>   
                            <td class="capitalize">{{banner?.status?.toLowerCase()}}</td>
                            <td>
                                <button class="btn bg-transparent" *ngIf="userService.rolesArrayPermission.includes('editAccounts')"
                                    [routerLink]="['/accounts/addAccounts', banner?.id]">
                                    <a class="edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a>
                                </button>
                                <button class="btn bg-transparent">
                                    <a class="view" title="view" data-toggle="tooltip"
                                        (click)="viewBannerDetails(viewBanners, banner?.id)"><i
                                            class="material-icons">visibility</i></a>
                                </button>
            
                            </td>
            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <!-- <div class="text-center wd-100" *ngIf="bannersList && bannersList.length == 0">
        No records found
    </div> -->
</div>

<ng-template #viewBanners let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="container emp-profile">
                <form method="post">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="float-right" style="width: 80px;">
                                <input type="submit" *ngIf="userService.rolesArrayPermission.includes('editAccounts')"
                                    [routerLink]="['/accounts/addAccounts', viewBanner?.id]" class="profile-edit-btn"
                                    (click)="closeModal()" name="btnAddMore" value="Edit" />
                            </div>
                           
                            <div class="row">
                                <div class="col-md-4"></div>
                                <div *ngIf="fileToReturn" class="mt-2 text-center pointer col-md-4">
                                    <button (click)="uploadBannerImage(viewBanner?.id)">Upload</button>
                                </div>
                                <div class="col-md-4"></div>
                            </div>
                        </div>

                        <div class="col-md-12">
                           
                            <div class="profile-head">
                                <h5>
                                    {{viewBanner?.name}}
                                </h5>
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home"
                                            role="tab" aria-controls="home" aria-selected="true">About</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content profile-tab" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="row" *ngIf="viewBanner?.name">
                                        <div class="col-md-4">
                                            <label>Name</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewBanner?.name}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewBanner?.status">
                                        <div class="col-md-4">
                                            <label>Status</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p class="capitalize">{{viewBanner?.status?.toLowerCase()}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewBanner?.description">
                                        <div class="col-md-4">
                                            <label>Description</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewBanner?.description}}</p>
                                        </div>
                                    </div>
                                   
                                 
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <button (click)="closeModal()" class="btn btn-primary float-right">OK</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #cropeImage let-close="close">
    <div class="modal-header">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 2"
            (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary"
            (click)="close(); resetSelectedImage(fileInput)">Cancel</button>
        <button type="button" class="btn btn-outline-secondary" (click)="close()">Confirm</button>
    </div>
</ng-template>