import { Component, OnInit, ViewChild ,HostListener} from '@angular/core';
import { BranchesService } from '../../services/branches.service';
import { UserService } from '../../services/user.service';
import {KnowlegebaseService} from '../../services/knowlegebase.service';


@Component({
  selector: 'app-knowlegebase',
  templateUrl: './knowlegebase.component.html',
  styleUrls: ['./knowlegebase.component.css']
})
export class KnowlegebaseComponent implements OnInit {  
  
  elements: any = [];
  headElements = ['ID', 'First', 'Last', 'Handle'];
  searchText: string = '';
  previous: string;
  dtOptions: DataTables.Settings = {};

  bannersList: any[] = [];
    constructor(public knowlegebaseService:KnowlegebaseService,public bannerService: BranchesService,public userService:UserService) {}

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true
    };
    
    this.getKnowlegebaseList();
  }



  getKnowlegebaseList(){
    this.knowlegebaseService.getknowlegebaseList().subscribe((result: any)=>{
      console.log(result);
     this.bannersList = result;
     })
  }

 
}

