
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray, } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import {ProductService} from '../../services/product.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})
export class CreateProductComponent implements OnInit {
  cursorArray:any={};
  productsubcategoryList:any=[];
  validationForm: FormGroup;
  productcategoryList:any;
  croppedImage: any = '';
  fileToReturn: File;imgURL: any;
  imageChangedEvent: any = '';
  createproduct: FormGroup;
  editProduct: any;
  editSection: boolean = true;
  productId: any;
  editedProduct: any;
  formtitle: string = "Add Product";
  snackBarDimension: any = {
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
    duration: 5000,
};


ValueProduct:any;
labelProduct:any;
public custom_fieldsArray = [];
     constructor(public route: ActivatedRoute,public productService:ProductService, 
      private modalService: NgbModal,private fb:FormBuilder,
      private _snackBar: MatSnackBar, public userService: UserService,public rout: Router,
      
       ) {
    
        }

        addCustomerField(){
          var label = this.labelProduct;
          var o = {[label] : this.ValueProduct };
          
          this.custom_fieldsArray.push(o);
          console.log(this.custom_fieldsArray);
        }
   
        ngOnInit(): void {
          
          this.getProductcategoryList();

          this.createproduct =  new FormGroup({
            'product': new FormGroup({
                'product_category_id': new FormControl(null, [Validators.required]),
                'title': new FormControl(null, [Validators.required, Validators.minLength(3)]),
                'status': new FormControl(null, [Validators.required]),
                'description': new FormControl(null, [Validators.required, Validators.minLength(3)]),
                custom_fields: this.fb.array([])
                
               
            }),
            
           
          });
      
          this.route.params.subscribe(param => {
            console.log("Routed data", param);
            this.productId = param.id;
          });
          if (this.productId) {
            console.log(this.userService)
            this.getProductDetail()
            
          }
        }
       
    

        getProductcategoryList(){
          this.productService.getProductcategoryList().subscribe((result: any)=>{
            console.log(result);
          this.productcategoryList = result;
           })
        }

    

        selectCategory(category){
          console.log(category);
          this.getproductsubCategory(category)
        }


        getproductsubCategory(id){
          this.productService.getproductSubcategorylist(id).subscribe(result => {
            console.log(result)
            if(result){

            }else{
              this.productsubcategoryList = result;
            }
            },
            error => {
              console.log(error);
             
             this._snackBar.open('Something Wrong', 'Ok', {
               horizontalPosition: 'end',
               verticalPosition: 'bottom',
                duration: 5000,
             });
            });
        }
  getProductDetail() {
    this.userService.setLoader(true);
    this.productService.getProduct(this.productId).subscribe(result => {
      this.userService.setLoader(false);
      this.editedProduct = result;
      this.formtitle = "Edit Product";
      this.cursorArray=JSON.parse(this.editedProduct.custom_fields);
      console.log(JSON.parse(this.editedProduct.custom_fields));
      this.editSection = false;
      
    }, error => {
      this.userService.setLoader(false);
      this._snackBar.open('Product Detatils not found', 'Ok', {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    })
  }

  

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = window.atob(arr[1]);
    let n = bstr.length;
    // let u8arr = new Uint8Array(n);
    let u8arr = new Uint8Array(new ArrayBuffer(n));

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

imageCropped(event: ImageCroppedEvent) {
  this.croppedImage = event.base64;
  this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
  );
  if (this.fileToReturn) {
      const file = this.fileToReturn;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToReturn);
      reader.onload = (_event) => {
          this.imgURL = reader.result;
      };
  }
}
imageLoaded(event) {
}
cropperReady(event) {
}
loadImageFailed(event) {
}
resetSelectedImage(element) {
  this.imgURL = '';
}
  
onSubmit(form) {
 console.log( this.createproduct?.value);
  
  form.product.custom_fields.push();
  console.log((form.product.custom_fields));
  const formData = new FormData();
  formData.append('product[product_category_id]',form.product.product_category_id)
  formData.append('product[title]', form.product.title);
  formData.append('product[status]', form.product.status);
  formData.append('product[description]', form.product.description);
  formData.append('product[custom_fields]',(JSON.stringify(form.product.custom_fields)));
  if (this.fileToReturn) {
      formData.append('product[asset_attributes][asset_file]', this.fileToReturn);
      formData.append('product[asset_attributes][file_name]', this.fileToReturn.name);
  }
  if (this.productId) {
      this.productService.getProductUpdate(formData, this.productId).subscribe(result => {
          this._snackBar.open('Product updated successfully', 'Ok', this.snackBarDimension);
          this.rout.navigate(['products']);
      }), error => {
          this._snackBar.open('Failed to update. Please try again', 'Ok', this.snackBarDimension);
      }
  } else {
      console.log(formData);
      this.productService.addProductLDetails(formData).subscribe(result => {
          this._snackBar.open('Product created successfully', 'Ok', this.snackBarDimension);
          this.rout.navigate(['products']);
      }), error => {
          this._snackBar.open('Failed to create. Please try again', 'Ok', this.snackBarDimension);
      }
  }
}

  cancel(){

  }


  onFileSelect(event: any, emp): void {
    console.log(event);
    if(event && event.target.files.length > 0){
        this.imageChangedEvent = event;
        this.modalService.open(emp, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          
        }, (reason) => {
           
        });
    }
}
uploadProductImage(event: any, createClassroom): void {
  this.imageChangedEvent = event;
  this.modalService.open(createClassroom, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    
  }, (reason) => {
     
  });
}


  
quantities() : FormArray {
  return this.createproduct.get("product.custom_fields") as FormArray
}
 
newQuantity(): FormGroup {
  return this.fb.group({
    label: '',
    value: '',
  })
}
 
addQuantity() {
  this.quantities().push(this.newQuantity());
}
 
removeQuantity(i:number) {
  this.quantities().removeAt(i);
}
}
