import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryService {

  baseUrl = environment.baseUrl;
  constructor(private httpRequest: HttpClient) { 
    // this.geturl();
  }
  createProductCategory = (productCategoryetails) => {
    return this.httpRequest.post(this.baseUrl + 'product_categories', productCategoryetails);
  }

  updateProductCategory = (productCategoryetails, productCategoryId) => {
    return this.httpRequest.patch(this.baseUrl + 'product_categories/' + productCategoryId, productCategoryetails);
  }

  getProductCategoryList = () => {
    return this.httpRequest.get(this.baseUrl + 'product_categories');
  }

  getProductCategory = (productCategoryId) => {
    return this.httpRequest.get(this.baseUrl + 'product_categories/' + productCategoryId);
  }

  getProductsubCategory = (productCategoryId) => {
    return this.httpRequest.get(this.baseUrl + 'product_categories/' + productCategoryId+'/sub_categories');
  }
}