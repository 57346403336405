import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../../services/user.service';
import { AuthService } from '../../services/auth.service';
declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
    showMenu = '';
    showSubMenu = '';
    public sidebarnavItems: any[];
    // this is for the open close
    addExpandClass(element: any) {
        const token = this.authService.isLoggedIn();
        if (token) {
            // this.router.navigate([element]);
            if (element === 'Dashboard') {
                this.userService.userRedirectToDashboard(this.authService.getUserRole());
            }

            if (element === 'Logout') {
                this.logout();
            }
        } else {
            this.router.navigateByUrl('/');
        }
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            console.log('element', element);
            this.showMenu = element;
        }
    }

    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element;
        }
    }

    logout(){
        localStorage.clear();
        this.router.navigateByUrl('/');
    }

    constructor(private modalService: NgbModal, private router: Router, private route: ActivatedRoute, private userService: UserService,
        public authService: AuthService) { }
    // End open close
    ngOnInit() {
        this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }
}
