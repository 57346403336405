import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class EditSocialGuardGuard implements CanActivate {
  userRole:any;
  constructor(private authService:AuthService,private _snackBar: MatSnackBar,private userService:UserService){
    this. userRole = this.authService.getUserRole();
  }
  canActivate() {
    if(this.userService.rolesArrayPermission.includes("editSocialMedia")){
   return true;
  }
  
  else {
 //   this.userService.setLoader(false);
   this._snackBar.open('You Dont have permission to acccess', 'Ok', {
     horizontalPosition: 'end',
     verticalPosition: 'bottom',
     duration: 5000,
   });
   //this.router.navigateByUrl('/');
   return false;
  }
  
}
}
