<!-- Header Layout Content -->
<div class="mdk-header-layout__content">

  <div class="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
    <div class="mdk-drawer-layout__content page">

      <div class="container-fluid page__heading-container">
        <div
          class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
          <h1 class="m-lg-0">Branch</h1>
                            <button class="btn btn-primary ml-lg-3 float-right" [routerLink]="['/role/addBranch']"><i class="material-icons mr-2">add_circle</i>ADD BRANCH</button>
                        </div>
                    </div>
                    <mat-form-field>
                      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                    </mat-form-field>
                    <div class="mat-elevation-z8">
                      <table class="table table-hover thead-light">
                        <thead>
                          <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>License End Date</th>
                            <th>License Registration No</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let partner of partnerArray">
                            <td>{{partner.id}}</td>
                            <td>{{partner.name}}</td>
                            <td>{{partner.phone}}</td>
                            <td>{{partner.email}}</td>
                            <td>{{partner.license_registration_no}}</td>
                            <td>{{partner.license_end_date}}</td>
                            <td>{{partner.status}}</td>
                            <td>
                             
                              <button class="btn bg-transparent"  [routerLink]="['/role/editBranch', partner?.id]"> 
                                <!-- [routerLink]="['/quiz/createQuiz', data?.id]" -->
                                <a class="edit" title="Edit" data-toggle="tooltip"><i
                                    class="material-icons">&#xE254;</i></a></button>
                             
                                    <!-- <button class="btn bg-transparent"> <a class="delete" title="Delete" data-toggle="tooltip"
                                  (click)="DeletePartner(partner.id)"><i class="material-icons">&#xE872;</i></a></button> -->
                              
                                  <button class="btn bg-transparent"> <a class="view" title="view" data-toggle="tooltip"><i
                                    class="material-icons">visibility</i></a></button>
              
                            </td>
              
                          </tr>
                        </tbody>
              
                      </table>
                      <!-- <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 table-hover">
                                    
                                       
                                        <ng-container matColumnDef="id">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 50px;"> # </th>
                                          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                                        </ng-container>
                                    
                                        
                                        <ng-container matColumnDef="employeeId">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 120px;"> Employee ID </th>
                                          <td mat-cell *matCellDef="let row"> {{row.employeeId}} </td>
                                        </ng-container>
                                        
                                        <ng-container matColumnDef="name">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                                          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                                        </ng-container>
              
                                        
                                        <ng-container matColumnDef="website">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Website </th>
                                          <td mat-cell *matCellDef="let row"> {{row.website}} </td>
                                        </ng-container>
                                    
                                       
                                        <ng-container matColumnDef="email">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                          <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                                        </ng-container>
              
                                        <ng-container matColumnDef="status">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 80px;"> Status </th>
                                          <td mat-cell *matCellDef="let row"> {{row.status}} </td>
                                        </ng-container>
              
                                        <ng-container matColumnDef="action">
                                          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 80px;"> Action </th>
                                          <td mat-cell *matCellDef="let row"> 
                                              <span class="material-icons pointer" [routerLink]="['/partner/addPartner', 3]">create</span>
                                              <span class="material-icons ml-2 pointer">delete</span>
                                           </td>
                                        </ng-container>
                                    
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </tr>
                                      </table> -->
              
                      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>
              
                  </div>
                  <!-- // END drawer-layout__content -->
                </div>
                <!-- // END drawer-layout -->
              
              </div>
              <!-- // END header-layout__content -->