import { Component, OnInit } from '@angular/core';
import { SocialMediaService } from '../../services/social-media.service';
import { UserService } from '../../services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-social-media',
    templateUrl: './social-media.component.html',
    styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

    socialmediaArray: any[] = [];
    viewSocial:any;
    snackBarDimension: any = {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 5000,
    };
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();

    constructor(public UserService: UserService,public socialService:SocialMediaService,public modalService: NgbModal , public SocialmediaService: SocialMediaService, private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        this.getSocialmediaList();
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10
        };
    }


    viewSocialDetails(views, id) {
        
        this.socialService.getSingleSocialDetails(id).subscribe(result => {
            this.viewSocial =result;
              console.log("this.viewSocial:",this.viewSocial);
              this.modalService.open(views, { ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true }).result.then((result) => {
            }, (reason) => {
            });
             
          }), error => {
            this._snackBar.open('Can not get Social details', 'Ok', this.snackBarDimension);
        }

    }

    closeModal = () => {
        this.modalService.dismissAll();
    }

    
    getSocialmediaList() {
       
        this.UserService.setLoader(true);
        this.SocialmediaService.getSocialMediaList().subscribe((socialmediaDetal: any) => {
            this.socialmediaArray = socialmediaDetal;
            console.log("this.socialmediaArray",this.socialmediaArray);
            this.dtTrigger.next();
            this.UserService.setLoader(false);
        },
            (error) => {
                console.log(error)
                this.UserService.setLoader(false);
                this._snackBar.open('Social media Record not found', 'Ok', {
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    duration: 5000,
                });
            }
        );
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }


}
