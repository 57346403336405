<!-- Header Layout Content -->
<div class="mdk-header-layout__content">

    <div class="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
        <div class="mdk-drawer-layout__content page">

            <div class="container-fluid page__heading-container">
                <div
                    class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
                    <h1 class="m-lg-0">Social Media </h1>
                    <button class="btn btn-primary ml-lg-3 float-right"
                        *ngIf="UserService.rolesArrayPermission.includes('addSocialMedia')"
                        [routerLink]="['/socialMedia/addMedia']"><i class="material-icons mr-2">add_circle</i>Add
                        New</button>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                            <thead>
                                <tr>
                                    <th width="50">Id</th>
                                    <th>Media Type</th>
                                    <th>Link</th>
                                    <th>Status</th>
                                    <th wdith="100">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let partner of socialmediaArray">
                                    <td>{{partner.id}}</td>
                                    <td>
                                        <div class="media align-items-center">
                                            <div class="avatar avatar-xs mr-2">
                                                <!-- <img [src]="partner?.asset?.asset_expiring_thumb_url"
                                                    onerror="this.src='../../../assets/images/dummy.png'" alt="Avatar"
                                                    class="avatar-img rounded-circle"> -->
                                                    <i *ngIf="partner?.media_type == 'FACEBOOK'" class="fab fa-facebook fz-2"></i>
                                                    <i *ngIf="partner?.media_type == 'TWITTER'" class="fab fa-twitter fz-2"></i>
                                                    <i *ngIf="partner?.media_type == 'INSTAGRAM'" class="fab fa-instagram fz-2"></i>
                                                    <i *ngIf="partner?.media_type == 'LINKEDIN'" class="fab fa-linkedin fz-2"></i>
                                                    <i *ngIf="partner?.media_type == 'WHATSAPP'" class="fab fa-whatsapp fz-2"></i>
                                                    <i *ngIf="partner?.media_type == 'REDDIT'" class="fab fa-reddit fz-2"></i>
                                                    <i *ngIf="partner?.media_type == 'YOUTUBE'" class="fab fa-youtube fz-2"></i>
                                            </div>
                                            <div class="media-body">
                                                <span class="js-lists-values-employee-name">{{partner.media_type}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <a href="{{partner.link}}" target="_blank">{{partner.link}}</a>
                                    </td>
                                    <td>{{partner.status}}</td>
                                    <td>
                                        <button class="btn bg-transparent"
                                            *ngIf="UserService.rolesArrayPermission.includes('editSocialMedia')"
                                            [routerLink]="['/socialMedia/addMedia', partner?.id]">
                                            <a class="edit" title="Edit" data-toggle="tooltip"><i
                                                    class="material-icons">&#xE254;</i></a></button>
            
                                        <button class="btn bg-transparent"> <a class="view" title="view" 
                                            data-toggle="tooltip" (click)="viewSocialDetails(viewBanners, partner?.id)"><i
                                                    class="material-icons">visibility</i></a></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewBanners let-modal>
        <div class="modal-content">
                <div class="modal-body">
                    <div class="container emp-profile">
                        <form method="post">
                                <div class="row">
                                        <div class="col-md-12">
                                                <div class="float-right" style="width: 80px;">
                                                        <input type="submit" *ngIf="UserService.rolesArrayPermission.includes('editSocialMedia')"
                                                        [routerLink]="['/socialMedia/addMedia',viewSocial.id]" class="profile-edit-btn"
                                                        (click)="closeModal()" name="btnAddMore" value="Edit" />
                                                </div>
                                                <div class="row">
                                                        <!-- update -->
                                                 </div>
                                        </div>   <!-- col-md-12 end -->


                                        <div class="col-md-12">
                                                <div class="profile-head">
                                                        
                                                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                                                            <li class="nav-item">
                                                                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home"
                                                                    role="tab" aria-controls="home" aria-selected="true">About</a>
                                                                
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div class="tab-content profile-tab" id="myTabContent">
                                                            <div class="tab-pane fade show active" id="home" role="tabpanel"
                                                                aria-labelledby="home-tab">

                                                                <div class="row" *ngIf="viewSocial.media_type">
                                                                        <div class="col-md-4">
                                                                            
                                                                        </div>
                                                                        <div class="col-md-8">
                                                                            <p><i *ngIf="viewSocial.media_type == 'FACEBOOK'" class="fab fa-facebook fz-2"></i>
                                                                                <i *ngIf="viewSocial.media_type == 'TWITTER'" class="fab fa-twitter fz-2"></i>
                                                                                <i *ngIf="viewSocial.media_type == 'INSTAGRAM'" class="fab fa-instagram fz-2"></i>
                                                                                <i *ngIf="viewSocial.media_type == 'LINKEDIN'" class="fab fa-linkedin fz-2"></i>
                                                                                <i *ngIf="viewSocial.media_type == 'WHATSAPP'" class="fab fa-whatsapp fz-2"></i>
                                                                                <i *ngIf="viewSocial.media_type == 'REDDIT'" class="fab fa-reddit fz-2"></i>
                                                                                <i *ngIf="viewSocial.media_type == 'YOUTUBE'" class="fab fa-youtube fz-2"></i></p> 
                                                                        </div>
                                                                    </div>
                                                                <div class="row" *ngIf="viewSocial.title">
                                                                        <div class="col-md-4">
                                                                            <label>Title</label>
                                                                        </div>
                                                                        <div class="col-md-8">
                                                                            <p>{{viewSocial.title}}</p> 
                                                                        </div>
                                                                    </div>
                                                                <div class="row" *ngIf="viewSocial.media_type">
                                                                    <div class="col-md-4">
                                                                        <label>media type</label>
                                                                    </div>
                                                                    <div class="col-md-8">
                                                                            <p>{{viewSocial.media_type}}</p>
                                                                            
                                                                    </div>
                                                                </div>
                                                                <div class="row" *ngIf="viewSocial.status">
                                                                    <div class="col-md-4">
                                                                        <label>Status</label>
                                                                    </div>
                                                                    <div class="col-md-8">
                                                                        <p class="capitalize">{{viewSocial.status}}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row" *ngIf="viewSocial.link">
                                                                        <div class="col-md-4">
                                                                            <label>Link</label>
                                                                        </div>
                                                                        <div class="col-md-8">
                                                                            <p class="capitalize">{{viewSocial.link}}</p>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </div>

                                    </div>        <!--  col-md-12 end  -->
                     </div>
                </form>
            </div>
            <div class="card-footer">
                <button (click)="closeModal()" class="btn btn-primary float-right">OK</button>
            </div>
        </div>
    </div>
</ng-template>
