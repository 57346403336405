import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './../services/auth.service';
import {EmployeeService}from './../services/employee.service';
import { UserService } from '../services/user.service';
@Injectable({
  providedIn: 'root'
})
export class CustomerGuard implements CanActivate {
  permissonArray:any;
  userRole:any;
  constructor(private userService:UserService,private authService:AuthService,private empService:EmployeeService ,private _snackBar: MatSnackBar,private router: Router){
    this. userRole = this.authService.getUserRole();
    console.log(this.userRole);
   
  }
  canActivate(router:ActivatedRouteSnapshot,state:RouterStateSnapshot) { 
     if(this.userService.rolesArrayPermission.includes("editCustomers") && (this.userRole ==='admin' || this.userRole === 'super_admin' || this.userRole === 'staff')){
    return true;
   }
   
   else {
  //   this.userService.setLoader(false);
    this._snackBar.open('You Dont have permission to acccess', 'Ok', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 5000,
    });
    //this.router.navigateByUrl('/');
    return false;
   }
  }
}
