import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { catchError ,retry} from 'rxjs/operators'
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
    providedIn: 'root'
  })
  export class  RequestService {
    baseUrl = environment.baseUrl;
    constructor(public  _snackBar: MatSnackBar,private httpClient: HttpClient) { 
        this.geturl();
      }
      geturl() {
        let url = this.baseUrl ;
        console.log(url)
        return this.httpClient.get<JSON>(url);
      }

      getRequestList(){
        return this.httpClient.get(this.baseUrl + 'requests').pipe(retry(2),
          catchError(this.handleError)
          );
    
      }
      addRequestDetails = (RequestData) => {
        console.log(RequestData+"service section")
        return this.httpClient.post(this.baseUrl + 'requests', RequestData).pipe(retry(2),catchError(this.handleError))
       
      }
  

      getRequest(id){
        console.log(JSON.stringify(id))
        return this.httpClient.get(this.baseUrl + 'requests/'+id)
        // return this.httpClient.get()
      }
      getRequestUpdate(id,RequestData){
        console.log(id +""+RequestData);
        return this.httpClient.patch(this.baseUrl+'requests/'+id,RequestData)
      }
      handleError(error: HttpErrorResponse){
        console.log(JSON.stringify(error.error))
        
          return throwError(error);
          }


  }