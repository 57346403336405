import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../config/environment';
const HttpUploadOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};

@Injectable({
    providedIn: 'root'
})
export class UserService {
    // private baseUrl = 'https://edurooms.herokuapp.com/';
    private baseUrl = environment.baseUrl;
    loaderStatus: any;
    rolesArrayPermission:any=[];
    subcategoryid: any;

    constructor(
        private httpRequest: HttpClient,
        private router: Router
    ) { }

    userRedirectToDashboard = (userRole) => {
        console.log(userRole)
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin' || userRole === 'staff') {
                this.router.navigate(['dashboard/admin']);
            }
            // if (userRole === 'teacher' || userRole === 'demo_teacher') {
            //     this.router.navigate(['dashboard']);
            // }
            // if (userRole === 'student' || userRole === 'demo_student') {
            //     this.router.navigate(['dashboard/dealer']);
            // }
        }
    }

    userRedirectToEmployee = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['employees']);
            }
        }
    }

    userRedirectToRole = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['role/details']);
            }
        }
    }

    userRedirectToProducts = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['product/details']);
            }
        }
    }

    userRedirectToPartner = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['partner/details']);
            }
        }
    }


    userRedirectToBranch = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['branch/details']);
            }
        }
    }

    userRedirectToKnowledgebase = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['knowledgeBase/details']);
            }
        }
    }

    userRedirectToPromotional = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['promotional/details']);
            }
        }
    }

    userRedirectToBanners = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['banner/details']);
            }
        }
    }

    userRedirectToSocialMedia = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['socialMedia/details']);
            }
        }
    }

    userRedirectToComplaint = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['complaint/details']);
            }
        }
    }

    userRedirectToSetting = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['setting/details']);
            }
        }
    }

    userRedirectToSupport = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['support/details']);
            }
        }
    }


    //old code
    userRedirectToVendors = (userRole) => {
        if (userRole) {

            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['vendor/details']);
            }

            if (userRole === 'teacher' || userRole === 'demo_teacher') {
                this.router.navigate(['vendor/details']);
            }

            if (userRole === 'student' || userRole === 'demo_student') {
                this.router.navigate(['quiz/studentQuiz']);
            }
        }
    }

    userRedirectToDealers = (userRole) => {
        if (userRole) {
            if (userRole === 'admin' || userRole === 'super_admin') {
                this.router.navigate(['dealer/details']);
            }

            if (userRole === 'teacher' || userRole === 'demo_teacher') {
                this.router.navigate(['dealer/details']);
            }

            if (userRole === 'student' || userRole === 'demo_student') {
                this.router.navigate(['quiz/studentQuiz']);
            }
        }
    }

    setLoader = (status) => {
        this.loaderStatus = status;
    }

    getLoader = () => {
        return this.loaderStatus;
    }

    getUserDetails = () => {
        return this.httpRequest.get(this.baseUrl + 'user');
    }

    updateUserDetails = (userDetail) => {
        return this.httpRequest.patch(this.baseUrl + 'user', userDetail);
    }

    userSignup = (userDetail) => {
        delete userDetail['terms'];
        if (userDetail.role_names !== 'admin' && userDetail.role_names !== 'super_admin') {
            delete userDetail['school_name'];
        }
        return this.httpRequest.post(this.baseUrl + 'users/registration', { user: userDetail });
    }

    updateUserProfile = (image) => {
        return this.httpRequest.patch(this.baseUrl + 'user', image);
    }
}
