import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {
  baseUrl = environment.baseUrl;
  constructor(private httpRequest: HttpClient) { 
    // this.geturl();
  }

  createBlog = (blogDetails) => {
    return this.httpRequest.post(this.baseUrl + 'blogs', blogDetails);
  }
  updateBlog = (blogDetails, blogId) => {
    return this.httpRequest.patch(this.baseUrl + 'blogs/' + blogId, blogDetails);
  }
  getBlogsList = () => {
    return this.httpRequest.get(this.baseUrl + 'blogs');
  }
  getBlog = (blogId) => {
    return this.httpRequest.get(this.baseUrl + 'blogs/' + blogId);
  }
}
