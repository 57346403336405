import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './auth-guard/auth.guard';
import { UsableUIComponent } from './usable-ui/usable-ui.component';
import { HomeComponent } from './home/home.component';
import { MultiAccountUploadComponent } from './multi-account-upload/multi-account-upload.component';
import {ErrorComponentComponent} from'./error-component/error-component.component';
export const routes: Routes = [

    {
        path: '',
        component: FullComponent,
        children: [
            { path: 'usable-ui', component: UsableUIComponent },
            { path: '', component: HomeComponent },
            {
                path: 'login',
                loadChildren: () => import('./userAuth/user-auth.module').then(m => m.UserAuthModule)
            },
            {path:"MultiAccountUploadComponent",component:MultiAccountUploadComponent},
            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'banners',
                canActivate: [AuthGuard],
                loadChildren: () => import('./banners/banner.module').then(m => m.BannerModule)
            },
            {
                path: 'accounts',
                canActivate: [AuthGuard],
                loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
            },

       

            
            {
                path: 'customeraccounts',
                canActivate: [AuthGuard],
                loadChildren: () => import('./customerAccount/customeraccounts.module').then(m => m.customerAccountsModule)
            },
                
            {
                path: 'customeraccountsbalance',
                canActivate: [AuthGuard],
                loadChildren: () => import('./customerAccountBalance/customeraccountsbalance.module').then(m => m.customerAccountsBalaceModule)
            },

            {
                path: 'customeraccountstransaction',
                canActivate: [AuthGuard],
                loadChildren: () => import('./customerAccountTransaction/customerAccountTransaction.module').then(m => m.customerAccountTransactionModule)
            },

            

            {
                path: 'request',
                canActivate: [AuthGuard],
                loadChildren: () => import('./request/request.module').then(m => m.RequestModule)
            },

            {
                path: 'complaints',
                canActivate: [AuthGuard],
                loadChildren: () => import('./complaint/complaint.module').then(m => m.ComplaintModule)
            },
            {
                path: 'knowledgeBase',
                canActivate: [AuthGuard],
                loadChildren: () => import('./knowledge-base/knowledgebase.module').then(m => m.KnowledgeBaseModule)
            },
            {
                path: 'cooperatives',
                canActivate: [AuthGuard],
                loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule)
            },
            {
                path:'branches',
                canActivate:[AuthGuard],
                loadChildren:()=>import('./branches/branches.module').then(m =>m.BranchModule)
            },
            {
                path: 'promotionals',
                canActivate: [AuthGuard],
                loadChildren: () => import('./promotional/promotional.module').then(m => m.PromotionalModule)
            },

            {
                path: 'settings',
                canActivate: [AuthGuard],
                loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
            },
            {
                path: 'support',
                canActivate: [AuthGuard],
                loadChildren: () => import('./support/support.module').then(m => m.SupportModule)
            },
            {
                path: 'socialMedia',
                canActivate: [AuthGuard],
                loadChildren: () => import('./social-media/socialMedia.module').then(m => m.SocialMediaModule)
            },
            {
                path: 'employees',
                canActivate: [AuthGuard],
                loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule)
            },

            {
                path: 'customer',
                canActivate: [AuthGuard],
                loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
            },

            {
                path: 'products',
                canActivate: [AuthGuard],
                loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
            },
            {
                path: 'productscategories',
                canActivate: [AuthGuard],
                loadChildren: () => import('./productCategory/productCategory.module').then(m => m.ProductCategoryModule)
            },
            {
                path: 'productsubcategories',
                canActivate: [AuthGuard],
                loadChildren: () => import('./productsubCategory/productsubCategory.module').then(m => m.ProductsubCategoryModule)
            },
            {
                path: 'blogs',
                canActivate: [AuthGuard],
                loadChildren: () => import('./blogs/blog.module').then(m => m.BlogModule)
            },
            {
                path: 'component',
                loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
            },
            { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
            { path: 'forms', loadChildren: () => import('./form/forms.module').then(m => m.FormModule) },
            { path: 'tables', loadChildren: () => import('./table/tables.module').then(m => m.TablesModule) },
            { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartModule) },
            {
                path: 'widgets',
                loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
            },
            {
                path: 'extra-component',
                loadChildren:
                    () => import('./extra-component/extra-component.module').then(m => m.ExtraComponentModule)
            },
            { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
            {
                path: 'apps/email',
                loadChildren: () => import('./apps/email/mail.module').then(m => m.MailModule)
            },
            {
                path: 'sample-pages',
                loadChildren: () => import('./sample-pages/sample-pages.module').then(m => m.SamplePagesModule)
            }
        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    {
        path:"**",
        component:ErrorComponentComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes), NgbModule],
    exports: [RouterModule]
})
export class AppRoutingModule { }
