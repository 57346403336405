import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { catchError ,retry} from 'rxjs/operators'
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl = environment.baseUrl;
  partnerUrl:any;

  constructor(public  _snackBar: MatSnackBar,private httpClient: HttpClient) {
    this.geturl();
  }
  geturl() {
    let url = this.baseUrl ;
    console.log(url)
    return this.httpClient.get<JSON>(url);
  }


  getProductList = () => {
    return this.httpClient.get(this.baseUrl + 'products').pipe(retry(2),
      catchError(this.handleError)
      );

  }
  getProductcategoryList = () => {
    return this.httpClient.get(this.baseUrl + 'product_categories').pipe(retry(2),
      catchError(this.handleError)
      );

  }
  getproductSubcategorylist(id){
    //https://rabiz-rails-api.herokuapp.com/product_categories/1/sub_categories
    console.log(JSON.stringify(id))
    return this.httpClient.get(this.baseUrl + 'product_categories/'+id+"/sub_categories")
  }



  addProductLDetails = (ProductData) => {
    console.log(ProductData+"service section")
    return this.httpClient.post(this.baseUrl + 'products', ProductData).pipe(retry(2),catchError(this.handleError))

  }
  getProduct=(id)=>{
    console.log(JSON.stringify(id))
    return this.httpClient.get(this.baseUrl + 'products/'+id);

    // return this.httpClient.get()

  }

  getProductUpdate(ProductData, id){
    return this.httpClient.patch(this.baseUrl+'products/'+id, ProductData);
  }

  handleError(error: HttpErrorResponse){
    console.log(JSON.stringify(error.error))

      return throwError(error);
      }
}
