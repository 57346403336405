import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ProductsubcategoryService {

  baseUrl = environment.baseUrl;
  constructor(private httpRequest: HttpClient) { 
    // this.geturl();
  }
  createProductsubCategory = (productsubCategoryetails) => {
    return this.httpRequest.post(this.baseUrl + 'product_categories', productsubCategoryetails);
  }

  updateProductsubCategory = (productsubCategoryetails, productsubCategoryId) => {
    return this.httpRequest.patch(this.baseUrl + 'product_categories/' + productsubCategoryId, productsubCategoryetails);
  }

  getProductsubCategoryList = () => {
    return this.httpRequest.get(this.baseUrl + 'product_categories');
  }

  getProductsubCategory = (productsubCategoryId) => {
    return this.httpRequest.get(this.baseUrl + 'product_categories/' + productsubCategoryId+'/sub_categories');
  }
  getProductSubCategoryList(productCaregoryId){
    return this.httpRequest.get(this.baseUrl +"product_categories/"+productCaregoryId)

  }
  updateSubCategoryDetail(productsubCategoryId){
    return this.httpRequest.get(this.baseUrl +"product_categories/"+productsubCategoryId)
  }

}
