import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../config/environment';
@Injectable({
  providedIn: 'root'
})
export class customerAccountsbalanceService {
  private baseUrl = environment.baseUrl;

  constructor(private httpRequest: HttpClient) {
  }



  getcustomerAccountsList = () => {
    return this.httpRequest.get(this.baseUrl + 'customer_account_balances');
  }

  createcustomerBalance = (bannerDetails) => {
    return this.httpRequest.post(this.baseUrl + 'customer_account_balances', bannerDetails);
  }

  bulkCreatecustomerBalance = (bannerDetails) => {
    return this.httpRequest.post(this.baseUrl + 'customer_account_balances', bannerDetails);
  }
  getcustomerAccounts = (bannerId) => {
    return this.httpRequest.get(this.baseUrl + 'customer_account_balances/' + bannerId);
  }




  updateAccounts = (bannerDetails, bannerId) => {
    return this.httpRequest.patch(this.baseUrl + 'customer_account_balances/' + bannerId, bannerDetails);
  }

  dropdownAccount() {
    return this.httpRequest.get(this.baseUrl + "customer_accounts.lk?active=true")
  }

  dropdownCustomer() {
    return this.httpRequest.get(this.baseUrl + "customers.lk?active=true")
  }


}
