import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
    // {
    //     path: '',
    //     title: 'Admin',
    //     icon: 'icon-tu_dashboard',
    //     class: 'nav-small-cap',
    //     label: '',
    //     labelClass: '',
    //     extralink: true,
    //     submenu: []
    // },
    {
        path: '/dashboard/admin',
        title: 'Dashboard',
        icon: 'icon-tu_dashboard',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],//roles will change after service changes.
        submenu: []
    },
    {
        path: 'cooperatives',
        title: 'Co-Operatives',
        icon: 'icon-quize',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['super_admin'],
        submenu: []
    },

    {
        path: 'branches',
        title: 'Branches',
        icon: 'icon-quize',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin','super_admin', 'staff'],
        submenu: []
    },
    {
        path: 'employees',
        title: 'Employees',
        icon: 'icon-st_courses',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },
    {
        path: 'customer',
        title: 'Customers',
        icon: 'icon-st_courses',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },


    /*{
        path: 'productsubcategories',
        title: 'sub-categories',
        icon: 'icon-quize',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin'],
        submenu: []
    },*/
    {
        path: 'customeraccounts',
        title: 'Customer Accounts',
        icon: 'icon-quize',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },    {
        path: 'customeraccountsbalance',
        title: 'Customer Balance',
        icon: 'icon-quize',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },

       {
        path: 'customeraccountstransaction',
        title: 'Accounts Transactions',
        icon: 'icon-quize',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },


    {
        path: 'accounts',
        title: 'Account Types',
        icon: 'icon-quize',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },
  {
    path: 'products',
    title: 'Products',
    icon: 'icon-quize',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor',
    extralink: false,
    isActiveFor: ['admin', 'super_admin'],
    submenu: []
  },
  {
    path: 'socialMedia',
    title: 'Social Media',
    icon: 'icon-st_courses',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor',
    extralink: false,
    isActiveFor: ['admin', 'super_admin', 'staff'],
    submenu: []
  },
  {
    path: 'banners',
    title: 'Banners',
    icon: 'icon-quize',
    class: '',
    label: '',
    labelClass: 'label label-rouded label-themecolor',
    extralink: false,
    isActiveFor: ['admin', 'super_admin', 'staff'],
    submenu: []
  },

/*    {
        path: 'blogs',
        title: 'Blogs',
        icon: 'icon-st_courses',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },*/
    {
        path: 'request',
        title: 'Requests',
        icon: 'icon-quize',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },
    /*{
        path: 'complaints',
        title: 'Complaints',
        icon: 'icon-profile',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    },*/
    // {
    //     path: 'settings',
    //     title: 'Settings',
    //     icon: 'icon-quize',
    //     class: '',
    //     label: '',
    //     labelClass: 'label label-rouded label-themecolor',
    //     extralink: false,
    //     isActiveFor: ['admin', 'super_admin'],
    //     submenu: []
    // },
    /*{
        path: 'support',
        title: 'Support',
        icon: 'icn-st_courses',
        class: '',
        label: '',
        labelClass: 'label label-rouded label-themecolor',
        extralink: false,
        isActiveFor: ['admin', 'super_admin', 'staff'],
        submenu: []
    }
*/
];
