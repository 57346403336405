import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../services/user.service';
import { PartnerService } from '../../services/partner.service';
import { NgbModal ,NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-partner',
    templateUrl: './partner.component.html',
    styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {
    coperativeId:any;
    partnerArray: any[] = [];
    viewPartner: any;
    snackBarDimension: any = {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 5000,
    };
    imageChangedEvent: any;
    croppedImage: any;
    fileToReturn: File;
    imgURL: any;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();

    constructor(private _snackBar: MatSnackBar, public UserService: UserService, public partnerService: PartnerService,
        config: NgbModalConfig, private modalService: NgbModal) {

            config.backdrop = 'static';
            config.keyboard = false;

    }

    ngOnInit(): void {
        this.getPartnersList();
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10
        };
    }

    getPartnersList() {
        this.UserService.setLoader(true);
        this.partnerService.getPartnerList().subscribe((partner: any) => {
            this.partnerArray = partner;
            this.dtTrigger.next();
            this.UserService.setLoader(false);
        },
            (error) => {
                console.log(error)
                this.UserService.setLoader(false);
                this._snackBar.open('Partner not found', 'Ok', {
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    duration: 5000,
                });
            }
        );
    }

    DeletePartner = (id) => {
        this.UserService.setLoader(true);
        this.partnerService.deletePartner(id).subscribe((partner: any) => {
            this.partnerArray = partner;
            console.log(this.partnerArray);
            this.UserService.setLoader(false);
        },
            error => {
                console.log(error);
                this.UserService.setLoader(false);
                this._snackBar.open('Something Wrong', 'Ok', {
                    horizontalPosition: 'end',
                    verticalPosition: 'bottom',
                    duration: 5000,
                });
            });
    }

    viewPartnerDetails(partnerView, id) {
        // this.imgURL = null;
        this.partnerService.getPartner(id).subscribe(result => {
            this.viewPartner = result;
            // if(this.viewEmployee && this.viewEmployee.asset && this.viewEmployee.asset.asset_expiring_url){
            //     this.imgURL = this.viewEmployee.asset.asset_expiring_url;
            // }
            this.modalService.open(partnerView, { ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true }).result.then((result) => {
            }, (reason) => {
            });
        }), error => {
            this._snackBar.open('Can not get employee details', 'Ok', this.snackBarDimension);
        }
    }

    onFileSelect(event: any, partner): void {
        console.log(event);
        if (event && event.target.files.length > 0) {
            this.imageChangedEvent = event;
            this.modalService.open(partner, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            }, (reason) => {
            });
        }
    }

    base64ToFile(data, filename) {
        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = window.atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(new ArrayBuffer(n));
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    imageCropped(event: ImageCroppedEvent) {
        console.log(event);
        if (event.base64) {
            this.croppedImage = event.base64;
            this.fileToReturn = this.base64ToFile(
                event.base64,
                this.imageChangedEvent.target.files[0].name,
            );
            if (this.fileToReturn) {
                const file = this.fileToReturn;
                const reader = new FileReader();
                reader.readAsDataURL(this.fileToReturn);
                reader.onload = (_event) => {
                    this.imgURL = reader.result;
                };
            }
        }
    }

    imageLoaded(event) {
    }
    cropperReady(event) {
    }
    loadImageFailed(event) {
        this.imgURL = this.imgURL;
    }

    resetSelectedImage(element?) {
        this.fileToReturn = null;
        if (this.viewPartner.asset.asset_expiring_url) {
            this.imgURL = this.viewPartner.asset.asset_expiring_url;
        } else {
            this.imgURL = '';
        }
    }

    uploadPartnerImage(id) {
        const formData = new FormData();
        if (this.fileToReturn) {
            formData.append('partner[asset_attributes][asset_file]', this.fileToReturn);
            formData.append('partner[asset_attributes][file_name]', this.fileToReturn.name);
            // this.partnerService.updatePartnerProfile(formData, id).subscribe(result => {
            //     this._snackBar.open('Profile updated successfully', 'Ok', this.snackBarDimension);
            //     this.fileToReturn = null;
            // }), error => {
            //     this._snackBar.open('Failed to upload. Please try again', 'Ok', this.snackBarDimension);
            // }
        }
    }

    closeModal = () => {
        this.modalService.dismissAll();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

  openAccess(content, partner) {
    console.log(partner.id);
    this.coperativeId = partner.id;
    this.viewPartner = partner;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true}).result.then((result) => {
      console.log(result);
      if (result == 'process') {
        console.log(this.coperativeId);
        this.partnerService.switchToOtherBank(this.coperativeId).subscribe(result => {
          console.log(result);
          window.location.href = '/';
        });

      } else if (result == 'cancel') {

      }
    }, (reason) => {
      console.log(reason);
    });
  }
    // getEmployeesList() {
    //   this.userService.setLoader(true);
    //   this.employeesService.getemployeesList().subscribe(employeeList => {
    //     this.employeeList = employeeList;
    //     console.log(this.employeeList)
    //     this.userService.setLoader(false);
    //   },
    //     error => {
    //       this.userService.setLoader(false);
    //       this._snackBar.open('Employee not found', 'Ok', {
    //         horizontalPosition: 'end',
    //         verticalPosition: 'bottom',
    //         duration: 5000,
    //       });
    //     });
    // }

}
