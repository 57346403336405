import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { catchError ,retry} from 'rxjs/operators'
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class SocialMediaService  {
  baseUrl = environment.baseUrl;

  constructor(public  _snackBar: MatSnackBar,private httpClient: HttpClient) {
    this.geturl();
  }
  geturl() {
    let url = this.baseUrl ;
    console.log(url)
    return this.httpClient.get<JSON>(url);
  }
  getSocialUpdate(id,SocialData){
    return this.httpClient.patch(this.baseUrl+'social_media/'+id,SocialData)

  }


  getSocialMediaList = () => {
    return this.httpClient.get(this.baseUrl + 'social_media').pipe(retry(2),
      catchError(this.handleError)
      );

  }


  addSocialDetails = (SocialData) => {
    console.log(SocialData+"service section")
    return this.httpClient.post(this.baseUrl + 'social_media', SocialData).pipe(retry(2),catchError(this.handleError))

  }

  getSingleSocialDetails(SocialId) {
    return this.httpClient.get(this.baseUrl + 'social_media/' + SocialId);
  }

  handleError(error: HttpErrorResponse){
    console.log(JSON.stringify(error.error))

      return throwError(error);
      }
}
