import { Component, NgModule, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { UserService } from '../../services/user.service';
import { BlogsService } from '../../services/blogs.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-blog-list',
    templateUrl: './blog-list.component.html',
    styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {
    blogsList: any[] = [];
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();
    imgURL: any;
    fileToReturn: any;
    viewBlog: any;
    snackBarDimension: any = {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 5000,
    };
    constructor(public userService: UserService, public blogService: BlogsService, public modalService: NgbModal, public _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        this.getBannerList();
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10
        };
    }

    getBannerList() {
        this.blogService.getBlogsList().subscribe((result: any) => {
            console.log(result);
            this.blogsList = result;
            this.dtTrigger.next();
        })
    }

    viewBlogDetails(view, id) {
        this.imgURL = null;
        this.fileToReturn = null;
        this.blogService.getBlog(id).subscribe((result: any) => {
            this.viewBlog = result;
            console.log("this.viewBlog",result);
            console.log("this.viewBlog",this.viewBlog);
            
            if (this.viewBlog && this.viewBlog.asset && this.viewBlog.asset.asset_expiring_url) {
                this.imgURL = this.viewBlog.asset.asset_expiring_url;
            }
            this.modalService.open(view, { ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true }).result.then((result) => {
            }, (reason) => {
            });
        }), error => {
            this._snackBar.open('Can not get banner details', 'Ok', this.snackBarDimension);
        }
    }

    closeModal = () => {
        this.modalService.dismissAll();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

}
