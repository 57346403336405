 
        
        <nav id="sidebarnav"class="app-nav app-nav-main flex-grow-1">
            <ul class="app-menu list-unstyled accordion"  id="sidebarnav">
                <li [ngClass]="{'active': showMenu === sidebarnavItem.title}" *ngFor="let sidebarnavItem of sidebarnavItems">
                    <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true  && sidebarnavItem.isActiveFor.indexOf(authService.getUserRole()) !== -1">{{sidebarnavItem.title}}</div>
                    <!-- [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" -->
                    <a  class="nav-link" routerLink="/{{sidebarnavItem?.path}}" routerLinkActive="active"  [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink && sidebarnavItem.isActiveFor.indexOf(authService.getUserRole()) !== -1"
                        (click)="addExpandClass(sidebarnavItem.title)" style="text-decoration: none;">
                       <span class="nav-icon"><i [ngClass]="[sidebarnavItem.icon]"></i></span> 
                        <span class="hide-menu nav-link-text">{{sidebarnavItem.title}}
                            <span *ngIf="sidebarnavItem.label != '' " [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label}}</span>
                        </span>
                    </a>
                </li>
                
           </ul><!--//app-menu-->
        </nav><!--//app-nav-->
        