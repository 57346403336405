<!-- Header Layout Content -->
<div class="mdk-header-layout__content">
    <div class="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
        <div class="mdk-drawer-layout__content page">
            <div class="container-fluid page__heading-container">
                <div
                    class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
                    <h1 class="m-lg-0">Co-Operative</h1>
                    <button class="btn btn-primary ml-lg-3 float-right"
                        *ngIf="UserService.rolesArrayPermission.includes('addCooperatives')"
                        [routerLink]="['/cooperatives/addPartner']"><i class="material-icons mr-2">add_circle</i>Add
                        New</button>
                </div>
            </div>
            <div class="container-fluid">
                <div class="card">
                    <div class="card-body">
                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th style="min-width: 150px;" >License Reg. No</th>
                                    <th style="min-width: 150px;">License End Date</th>
                                 
                                    <th  >Status</th>
                                    <th style="min-width: 100px;">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let partner of partnerArray">
                                    <td>{{partner.id}}</td>
                                    <td>
                                        <div class="media align-items-center">
                                            <div class="avatar avatar-xs mr-2">
                                                <img [src]="partner?.asset?.asset_expiring_thumb_url"
                                                    onerror="this.src='../../../assets/images/dummy.png'" alt="Avatar"
                                                    class="avatar-img rounded-circle">
                                            </div>
                                            <div class="media-body">
                                                <span class="js-lists-values-employee-name">{{partner.name}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{partner.phone}}</td>
                                    <td style="text-decoration: underline; color:blue" (click)="sendMail()" title="send Email">{{partner.email}}</td>
                                    <!--email send from here--->
                                    <td>{{partner.license_registration_no}}</td>
                                    <td>{{partner.license_end_date}}</td>
                                   
                                    <td>{{partner.status}}</td>
                                    <td >

                                        <button class="btn bg-transparent"
                                            *ngIf="UserService.rolesArrayPermission.includes('editCooperatives')"
                                            [routerLink]="['/cooperatives/editCorporate', partner?.id]">
                                            <!-- [routerLink]="['/quiz/createQuiz', data?.id]" -->
                                            <a class="edit" title="Edit" data-toggle="tooltip"><i
                                                    class="material-icons">&#xE254;</i></a>
                                        </button>

                                        <!-- <button class="btn bg-transparent"> <a class="delete" title="Delete" data-toggle="tooltip"
                                (click)="DeletePartner(partner.id)"><i class="material-icons">&#xE872;</i></a></button> -->

                                        <button class="btn bg-transparent"

                                        > <a class="view" title="view" data-toggle="tooltip"><i
                                                    class="material-icons"
                                                    (click)="viewPartnerDetails(viewPartnerModal, partner.id)">visibility</i></a></button>

                                                    <button class="btn bg-transparent"
                                                    *ngIf="UserService.rolesArrayPermission.includes('AccessCompany')"
                                                    > <a class="view" title="view" data-toggle="tooltip"><i
                                                        class="material-icons"
                                                        (click)="openAccess(content,partner)">lock_open</i></a></button>



                                    </td>

                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #viewPartnerModal let-modal>
    <div class="modal-content">
        <div class="modal-body">
            <div class="container emp-profile">
                <form method="post">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="profile-img">
                                <img [src]="imgURL" onerror="this.src='../../../assets/images/dummy.png'" alt="" />
                                <div class="file btn btn-lg btn-primary">
                                    Upload Photo
                                    <input type="file" (change)="onFileSelect($event,cropeImage)" />
                                </div>
                                <div *ngIf="fileToReturn" class="mt-2 upload-btn">
                                    <button (click)="uploadPartnerImage(viewPartner?.id)">Upload</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="float-right" style="width: 80px;">
                                <input type="submit" [routerLink]="['/cooperatives/editCorporate', viewPartner?.id]"
                                    class="profile-edit-btn" (click)="closeModal()" name="btnAddMore" value="Edit" />
                            </div>
                            <div class="profile-head">
                                <h5>
                                    {{viewPartner?.name}}
                                </h5>
                                <h6>
                                </h6>
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home"
                                            role="tab" aria-controls="home" aria-selected="true">About</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content profile-tab" id="myTabContent">
                                <div class="tab-pane fade show active" id="home" role="tabpanel"
                                    aria-labelledby="home-tab">
                                    <div class="row" *ngIf="viewPartner?.name">
                                        <div class="col-md-4">
                                            <label>Name</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewPartner?.name}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewPartner?.email">
                                        <div class="col-md-4">
                                            <label>Email</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewPartner?.email}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewPartner?.phone">
                                        <div class="col-md-4">
                                            <label>Phone</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewPartner?.phone}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewPartner?.address">
                                        <div class="col-md-4">
                                            <label>Address</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p>{{viewPartner?.address?.address1}}<span
                                                    *ngIf="viewPartner?.address?.address2">,
                                                </span>{{viewPartner?.address?.address2}}<span
                                                    *ngIf="viewPartner?.address?.address3">, </span>
                                                {{viewPartner?.address?.address3}}<span
                                                    *ngIf="viewPartner?.address?.city">,
                                                </span>{{viewPartner?.address?.city}}<span
                                                    *ngIf="viewPartner?.address?.zipcode"> - </span>
                                                {{viewPartner?.address?.zipcode}}<span
                                                    *ngIf="viewPartner?.address?.state">,
                                                </span>{{viewPartner?.address?.state}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewPartner?.status">
                                        <div class="col-md-4">
                                            <label>Status</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p class="capitalize">{{viewPartner?.status?.toLowerCase()}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewPartner?.license_registration_no">
                                        <div class="col-md-4">
                                            <label>License Reg. No.</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p class="capitalize">{{viewPartner?.license_registration_no}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewPartner?.license_end_date">
                                        <div class="col-md-4">
                                            <label>License End Date</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p class="capitalize">{{viewPartner?.license_end_date}}</p>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="viewPartner?.pan_number">
                                        <div class="col-md-4">
                                            <label>Pan Number</label>
                                        </div>
                                        <div class="col-md-8">
                                            <p class="capitalize">{{viewPartner?.pan_number}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="card-footer">
                <button (click)="closeModal()" class="btn btn-primary float-right">OK</button>
            </div>
        </div>
    </div>
</ng-template>




<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Hi there!</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Are you sure, do you want to access <b>{{viewPartner?.name}}</b> Society?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="c('cancel')">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="c('process')">Confirm</button>
    </div>
  </ng-template>

