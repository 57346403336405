import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class KnowlegebaseGuard implements CanActivate {

  
  constructor(private _snackBar: MatSnackBar,private router: Router,private userService:UserService){

  }
  canActivate() {
   
    if(this.userService.rolesArrayPermission.includes("addKnowlegebase")){
    return true;
   }
 
   
   else {
 //   this.userService.setLoader(false);
    // return true;

    this._snackBar.open('You Dont have permission to acccess', 'Ok', {
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      duration: 5000,
    });
    //this.router.navigateByUrl('/');
    return false;
   }
}
  
}
