<div class="mdk-drawer-layout__content page">
  <div class="container-fluid page__heading-container">
    <div
      class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
      <h1 class="m-lg-0">Product category</h1>
      <button class="btn btn-primary ml-lg-3 float-right" 
      [routerLink]="['/productscategories/addproductCategory']">
      <i class="material-icons mr-2">add_circle</i>Add New</button>
    </div>
    <!-- *ngIf="userService.rolesArrayPermission.includes('addProductCategory')" -->
  </div>


  <div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
      <thead>
        <tr>
          <th width="50px">#</th>
          <th >Title</th>
       <th>sub Category</th>
          <th>Description</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let productCategory of productCategoryList; let index = index">
          <td>{{index + 1}}</td>
          <td>{{productCategory?.title}}</td>
          <td style="color: blue;cursor: pointer;"(click)="subCategory(productCategory?.id)">Sub Category</td>
          <td>{{productCategory?.description}}</td>
          <td class="capitalize">{{productCategory?.status?.toLowerCase()}}</td>
          <td>
            <button class="btn bg-transparent" 
            *ngIf="userService.rolesArrayPermission.includes('editProductCategory')"
            [routerLink]="['/productscategories/addproductCategory', productCategory?.id]">
              <a class="edit" title="Edit" data-toggle="tooltip"><i class="material-icons">&#xE254;</i></a>
            </button>
            <button class="btn bg-transparent">
              <a class="view" title="view" data-toggle="tooltip"><i class="material-icons"
                (click)="viewProductCategoryDetails(viewProductCategory, productCategory.id)"
                >visibility</i></a>
            </button>

          </td>

        </tr>
      </tbody>

    </table>
  </div>
  </div>
</div>
</div>



<ng-template #cropeImage let-close="close">
  <div class="modal-header">
      <image-cropper [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true" [aspectRatio]="4 / 2"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary"
          (click)="close(); resetSelectedImage(fileInput)">Cancel</button>
      <button type="button" class="btn btn-outline-secondary"
          (click)="close()">Confirm</button>
  </div>
</ng-template>





<ng-template #viewProductCategory let-modal >
  <div class="modal-content">
      <div class="modal-body p-4">
          <div class="container emp-profile">
              <form method="post">
                  <div class="row">
                      <div class="col-md-4">
                          <div class="profile-img">
                              <img src="assets/images/users/2.jpg" alt=""/>
                              <!-- <div class="file btn btn-lg btn-primary">
                                  Change Photo
                                  <input type="file" name="file"/>
                              </div> -->
                          </div>
                      </div>
                      <div class="col-md-6">
                          <div class="profile-head">
                                      <h5>
                                          {{viewProductCategory?.title}}
                                      </h5>
                                      <!-- <h6>
                                          <label class="capitalize">{{viewProductCategory?.designation?.toLowerCase()}}</label>
                                      </h6> -->
                                      <!-- <p class="proile-rating">RANKINGS : <span>8/10</span></p> -->
                              <ul class="nav nav-tabs" id="myTab" role="tablist">
                                  <li class="nav-item">
                                      <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">About</a>
                                  </li>
                                  <!-- <li class="nav-item">
                                      <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Timeline</a>
                                  </li> -->
                              </ul>
                          </div>
                      </div>
                      <div class="col-md-2">
                          <input type="submit" [routerLink]="['/productscategories/addproductCategory', viewProductCategory.id]" class="profile-edit-btn" (click)="closeModal()" name="btnAddMore" value="Edit"/>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-4 mt-2">
                          <div class="profile-work">
                              <!-- <p>Documents</p>
                              <a>Aadhaar: {{viewEmployee?.aadhar}}</a><br/>
                              <a>PAN: {{viewEmployee?.pan}}</a><br/>
                              
                              <p>Other Details</p>
                              <a>Date Of Birth: {{viewEmployee?.date_of_birth | date: 'mediumDate'}}</a><br/>
                              <a>Experience: {{viewEmployee?.total_experience}}</a><br/>
                              <a>Employee Code: {{viewEmployee?.employee_code}}</a><br/>
                              <a>Status: <span class="capitalize">{{viewEmployee?.status?.toLowerCase()}}</span></a><br/>
                              <a>Qualification: {{viewEmployee?.qualification}}</a><br/>
                              <a>Gender: <span class="capitalize">{{viewEmployee?.sex?.toLowerCase()}}</span></a><br/>
                              <a>Marital Status: <span class="capitalize">{{viewEmployee?.marital_status?.toLowerCase()}}</span></a><br/>
                              <a *ngIf="viewEmployee?.joining_date">Joining Date: {{viewEmployee?.joining_date}}</a><br/>
                              <a *ngIf="viewEmployee?.release_date">Release Date: {{viewEmployee?.release_date}}</a><br/> -->
                          </div>
                      </div>
                      <div class="col-md-8">
                          <div class="tab-content profile-tab" id="myTabContent">
                              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                          <!-- <div class="row">
                                              <div class="col-md-6">
                                                  <label>User Id</label>
                                              </div>
                                              <div class="col-md-6">
                                                  <p>{{userProfile?.id}}</p>
                                              </div>
                                          </div> -->
                                          <div class="row">
                                              <div class="col-md-6">
                                                  <label>Title</label>
                                              </div>
                                              <div class="col-md-6">
                                                 <p>{{viewEmployee?.title}}</p> 
                                              </div>
                                          </div>
                                          <div class="row">
                                              <div class="col-md-6">
                                                  <label>Email</label>
                                              </div>
                                              <div class="col-md-6">
                                                  <!-- <p>{{viewEmployee?.contact?.email}}</p> -->
                                              </div>
                                          </div>
                                          <div class="row">
                                              <div class="col-md-6">
                                                  <label>Phone</label>
                                              </div>
                                              <div class="col-md-6">
                                                  <!-- <p>{{viewEmployee?.contact?.phone}}</p> -->
                                              </div>
                                          </div>
                                          <div class="row">
                                              <div class="col-md-6">
                                                  <label>Address</label>
                                              </div>
                                              <!-- <div class="col-md-6">
                                                  <p>{{viewEmployee?.address?.address1}}, {{viewEmployee?.address?.address2}}, {{viewEmployee?.address?.address3}}, {{viewEmployee?.address?.city}} -  
                                                      {{viewEmployee?.address?.zipcode}}, {{viewEmployee?.address?.state}}</p>
                                              </div> -->
                                          </div>
                              </div>
                      
                          </div>
                      </div>
                  </div>
              </form>           
          </div>
          <div class="card-footer">
              <button (click)="closeModal()" class="btn btn-primary float-right">OK</button>
          </div>
      </div>
  </div>
</ng-template>