import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { BannersService } from '../../services/banners.service';
import { UserService } from '../../services/user.service';


@Component({
    selector: 'app-banners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.css']
})
export class BannersComponent implements OnInit {
    bannersList: any[] = [];
    imgURL: any;
    viewBanner: any;
    snackBarDimension: any = {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 5000,
    };
    imageChangedEvent: any;
    fileToReturn: File;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject<any>();

    constructor(public bannerService: BannersService, public userService: UserService, public modalService: NgbModal,
        public _snackBar: MatSnackBar) { }

    ngOnInit(): void {
        this.getBannerList();
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10
        };
    }

    getBannerList() {
        this.bannerService.getBannnersList().subscribe((result: any) => {
            console.log(result);
            this.bannersList = result;
            this.dtTrigger.next();
        })
    }

    viewBannerDetails(viewB, id) {
        this.imgURL = null;
        this.fileToReturn = null;
        this.bannerService.getBanner(id).subscribe(result => {
            this.viewBanner = result;
            if (this.viewBanner && this.viewBanner.asset && this.viewBanner.asset.asset_expiring_url) {
                this.imgURL = this.viewBanner.asset.asset_expiring_url;
            }
            this.modalService.open(viewB, { ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true }).result.then((result) => {
            }, (reason) => {
            });
        }), error => {
            this._snackBar.open('Can not get banner details', 'Ok', this.snackBarDimension);
        }
    }

    onFileSelect(event: any, emp): void {
        if (event && event.target.files.length > 0) {
            this.imageChangedEvent = event;
            this.modalService.open(emp, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                // this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    base64ToFile(data, filename) {
        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = window.atob(arr[1]);
        let n = bstr.length;
        // let u8arr = new Uint8Array(n);
        let u8arr = new Uint8Array(new ArrayBuffer(n));
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    imageCropped(event: ImageCroppedEvent) {
        console.log(event);
        if (event.base64) {
            this.fileToReturn = this.base64ToFile(
                event.base64,
                this.imageChangedEvent.target.files[0].name,
            );
            if (this.fileToReturn) {
                const file = this.fileToReturn;
                const reader = new FileReader();
                reader.readAsDataURL(this.fileToReturn);
                reader.onload = (_event) => {
                    this.imgURL = reader.result;
                };
            }
        }
    }

    imageLoaded(event) {
    }
    cropperReady(event) {
    }
    loadImageFailed(event) {
        this.imgURL = this.imgURL;
    }
    resetSelectedImage(element?) {
        this.fileToReturn = null;
        if (this.viewBanner.asset.asset_expiring_url) {
            this.imgURL = this.viewBanner.asset.asset_expiring_url;
        } else {
            this.imgURL = '';
        }
    }

    uploadBannerImage(id) {
        const formData = new FormData();
        if (this.fileToReturn) {
            formData.append('banner[asset_attributes][asset_file]', this.fileToReturn);
            formData.append('banner[asset_attributes][file_name]', this.fileToReturn.name);
            this.bannerService.updateBanner(formData, id).subscribe(result => {
                this._snackBar.open('Profile updated successfully', 'Ok', this.snackBarDimension);
                this.fileToReturn = null;
            }), error => {
                this._snackBar.open('Failed to upload. Please try again', 'Ok', this.snackBarDimension);
            }
        }
    }

    closeModal = () => {
        this.modalService.dismissAll();
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }
}

