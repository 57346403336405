import { Injectable } from '@angular/core';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { catchError ,retry} from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../config/environment';
@Injectable({
  providedIn: 'root'
})
export class CustomerTrnsactionAccount {
  private baseUrl = environment.baseUrl;

  constructor(private httpRequest: HttpClient) {
   }



   getAccountsList = () => {
    return this.httpRequest.get(this.baseUrl + 'customer_account_transactions');
  }

  createCustomerAccountTransaction = (bannerDetails) => {
    return this.httpRequest.post(this.baseUrl + 'customer_account_transactions', bannerDetails);
  }

  bulkCreateCustomerAccountTransaction = (bannerDetails) => {
    return this.httpRequest.post(this.baseUrl + 'customer_account_transactions', bannerDetails);
  }

  getAccounts = (bannerId) => {
    return this.httpRequest.get(this.baseUrl + 'customer_account_transactions/' + bannerId);
  }




  updateAccountTransactions = (bannerDetails, bannerId) => {
    return this.httpRequest.patch(this.baseUrl + 'customer_account_transactions/' + bannerId, bannerDetails);
  }

  handleError(error: HttpErrorResponse){
    console.log(JSON.stringify(error.error))
    
      return throwError(error);
      }

}