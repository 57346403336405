<div class="mdk-header-layout__content">
    <div class="mdk-drawer-layout js-mdk-drawer-layout" data-push data-responsive-width="992px">
      <div class="mdk-drawer-layout__content page">
        <div class="container-fluid page__heading-container">
          <div
            class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
            <h1 class="m-lg-0">Add Blog</h1>
            <button mat-stroked-button [routerLink]="['/blogs']">Back</button>
          </div>
        </div>
        <mat-card>
          <form [formGroup]="createBlog" #f="ngForm" (ngSubmit)="onSubmit(f.value)" novalidate>
            <div class="container">
              <div class="row" formGroupName="blog">
                <div class="form-group col-md-6">
                  <label for="title">Title</label>
                  <input type="text" class="form-control" id="title" name="title" placeholder="Title" formControlName="title" [ngModel]="editBlog?.title">
                </div>
              
                <div class="form-group col-md-6">
                  <label for="status">Status<sup style="color:red">*</sup></label>
                  <select class="form-control" id="status" style="height: 56% !important;" formControlName="status" [ngModel]="editBlog?.status">
                    <option value="ACTIVE">Active</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>
                </div>
                <div>
                    <div class="form-group col-md-12"> 
                      <!-- <label for="description">Description</label>
                      <input type="text" class="form-control" id="description" placeholder="Description" formControlName="title" formControlName="description" [ngModel]="editBlog?.description"> -->
                      <quill-editor id="description" formControlName="description" type="text" placeholder="Please enter a description" value="" 
                      [ngModel]="editBlog?.description"></quill-editor>
                    </div>
                </div>
                  <div class="form-group col-md-6">
                    <label for="startDate">Start date</label>
                    <input type="date" id="startDate" name="startDate" class="form-control" formControlName="start_date" [ngModel]="editBlog?.start_date">
                  </div>
                  <div class="form-group col-md-6">
                    <label for="endDate">End date</label>
                    <input type="date" id="endDate" name="endDate" class="form-control" formControlName="end_date" [ngModel]="editBlog?.end_date">
                  </div>
                  <div class="form-group col-md-4">
                      <div class="profile-img">
                          <img *ngIf="!imgURL" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS52y5aInsxSm31CvHOFHWujqUx_wWTS9iM6s7BAm21oEN_RiGoog" alt=""/>
                          <img *ngIf="imgURL" [src]="imgURL" alt=""/>
                          <div class="file btn btn-lg btn-primary">
                              Upload blog
                              <input type="file" name="file" accept="image/*" (change)="uploadBlog($event,cropeImage)"/>
                          </div>
                          <!-- <div>
                              <small>Dimensions: 245 x 163</small>
                          </div> -->
                      </div>
                  </div>
                  <div class="form-group col-md-12">
                      <button type="submit" class="btn btn-primary"
                      [disabled]="f.form.invalid" >Submit</button>
                      <button type="cancel" class="btn btn-primary ml-2" (click)="cancel()">cancel</button>
                  </div>
              </div>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  <ng-template #cropeImage let-close="close">
      <div class="modal-header">
          <image-cropper [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true" [aspectRatio]="4 / 2"
              (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady($event)"
              (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary"
              (click)="close(); resetSelectedImage(fileInput)">Cancel</button>
          <button type="button" class="btn btn-outline-secondary"
              (click)="close()">Confirm</button>
      </div>
  </ng-template>