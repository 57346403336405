import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient ,HttpErrorResponse} from '@angular/common/http';
import { catchError ,retry} from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class KnowlegebaseService {
  baseUrl = environment.baseUrl;
  constructor(private httpRequest: HttpClient) { 
    // this.geturl();
  }
  createKnowlegebase = (knowlegebaseDetails) => {
    return this.httpRequest.post(this.baseUrl + 'knowledgebases', knowlegebaseDetails);
  }

  updateKnowlegebase = (knowlegebaseDetails, knowlegebaseId) => {
    return this.httpRequest.patch(this.baseUrl + 'knowledgebases/' + knowlegebaseId, knowlegebaseDetails);
  }

  getknowlegebaseList = () => {
    return this.httpRequest.get(this.baseUrl + 'knowledgebases');
  }

  getknowlegebase = (knowlegebaseId) => {
    return this.httpRequest.get(this.baseUrl + 'knowledgebases/' + knowlegebaseId);
  }

  handleError(error: HttpErrorResponse){
    console.log(JSON.stringify(error.error))
    
      return throwError(error);
      }
}
