import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../config/environment';
@Injectable({
  providedIn: 'root'
})
export class customerAccountsService {
  private baseUrl = environment.baseUrl;

  constructor(private httpRequest: HttpClient) {
  }



  getcustomerAccountsList = () => {
    return this.httpRequest.get(this.baseUrl + 'customer_accounts');
  }

  createcustomerAccount = (bannerDetails) => {
    return this.httpRequest.post(this.baseUrl + 'customer_accounts', bannerDetails);
  }

  bulkCreatecustomerAccount = (bannerDetails) => {
    return this.httpRequest.post(this.baseUrl + 'customer_accounts', bannerDetails);
  }

  getcustomerAccounts = (bannerId) => {
    return this.httpRequest.get(this.baseUrl + 'customer_accounts/' + bannerId);
  }




  updateAccounts = (bannerDetails, bannerId) => {
    return this.httpRequest.patch(this.baseUrl + 'customer_accounts/' + bannerId, bannerDetails);
  }

  dropdownAccount() {
    return this.httpRequest.get(this.baseUrl + "customer_accounts.lk?active=true")
  }

  dropdownCustomer() {
    return this.httpRequest.get(this.baseUrl + "customers.lk?active=true")
  }

  dropdownCustomerAccount() {
    return this.httpRequest.get(this.baseUrl + "accounts.lk?active=true")
  }

  handleError(error: HttpErrorResponse) {
    console.log(JSON.stringify(error.error))

    return throwError(error);
  }


}
