import { Component, OnInit } from '@angular/core';
import { strict } from 'assert';
import readXlsxFile from 'read-excel-file'
type AOA = any[][];
@Component({
  selector: 'app-multi-account-upload',
  templateUrl: './multi-account-upload.component.html',
  styleUrls: ['./multi-account-upload.component.css']
})
export class MultiAccountUploadComponent implements OnInit {
  file:any;
  arrayBuffer:any;
  filelist:any;
  constructor() { }

  ngOnInit(): void {
  }



 

 

}
