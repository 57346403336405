import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators'
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

const HttpUploadOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};
@Injectable({
  providedIn: 'root'
})
export class BranchesService {
  baseUrl = environment.baseUrl;
  constructor(public _snackBar: MatSnackBar, private httpClient: HttpClient) {
    this.geturl();
  }
  geturl() {
    let url = this.baseUrl;
    console.log(url)
    return this.httpClient.get<JSON>(url);
  }
  getBranchList = () => {
    return this.httpClient.get(this.baseUrl + 'branches').pipe(retry(2),
      catchError(this.handleError)
    );

  }

  getBranchListForDropdown = () => {
    return this.httpClient.get(this.baseUrl + 'branches.lk?active=true').pipe(retry(2),
      catchError(this.handleError)
    );

  }

  addBranchDetails = (PartnerData) => {
    console.log(PartnerData + "service section")
    return this.httpClient.post(this.baseUrl + 'branches', PartnerData).pipe(retry(2), catchError(this.handleError))

  }
  getPartner = (id) => {
    console.log(JSON.stringify(id))
    return this.httpClient.get(this.baseUrl + 'branches/' + id)

    // return this.httpClient.get()

  }
  getPartnerUpdate(id, PartnerData) {
    return this.httpClient.patch(this.baseUrl + 'branches/' + id, PartnerData)
  }
  deletePartner = (id) => {
    return this.httpClient.delete(this.baseUrl + 'branches/' + id).pipe(retry(2), catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log(JSON.stringify(error.error))

    return throwError(error);
  }

  updateBranchProfile = (id, image) => {
    return this.httpClient.patch(this.baseUrl + 'branches/' + id, image);
  }
}
