import { Component, OnInit, ViewChild ,HostListener} from '@angular/core';
import { BranchesService } from '../../services/branches.service';
import { UserService } from '../../services/user.service';
import { ProductCategoryService } from '../../services/product-category.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-productCategoryDetail',
  templateUrl: './productCategoryDetail.component.html',
  styleUrls: ['./productCategoryDetail.component.css']
})
export class ProductCategoryComponent implements OnInit {  
  snackBarDimension: any = {
    horizontalPosition: 'end',
    verticalPosition: 'bottom',
    duration: 5000,
};

  viewProductCategory:any;
  elements: any = [];
  headElements = ['ID', 'First', 'Last', 'Handle'];
  searchText: string = '';
  previous: string;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();


  productCategoryList: any[] = [];
    constructor(public router:Router,public productCategoryService:ProductCategoryService,public userService:UserService,   public _snackBar: MatSnackBar,
      private modalService: NgbModal) {
      console.log(userService.rolesArrayPermission)
    }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };
    
    this.getproductCategoryList();
  }



  getproductCategoryList(){
    this.productCategoryService.getProductCategoryList().subscribe((result: any)=>{
      console.log(result);
     this.productCategoryList = result;
    this.dtTrigger.next();

     })
  }

  viewProductCategoryDetails(viewProductCategory, id){
    this.productCategoryService.getProductCategory(id).subscribe(result=>{
        this.viewProductCategory = result;
        this.modalService.open(viewProductCategory, {ariaLabelledBy: 'modal-basic-title', size: 'lg', scrollable: true}).result.then((result) => {
        }, (reason) => {
        });
        console.log(this.viewProductCategory);
        
    }), error =>{
        this._snackBar.open('Can not get employee details', 'Ok', this.snackBarDimension);
    }
}

closeModal = () =>{
  this.modalService.dismissAll();
}
subCategory(productCategoryid){
  this.userService.subcategoryid=productCategoryid;
  this.router.navigate(['/productsubcategories']);
  // this.productCategoryService.getProductsubCategory(productCategoryid).subscribe(result=>{

  // }),error=>{

  // }
}
 
}

