import { Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {BranchesService} from'../../services/branches.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import {UserService}from '../../services/user.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  partnerArray:any=[];
  displayedColumns: string[] = ['id', 'employeeId', 'name', 'website', 'email','status','action'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
    constructor(private _snackBar: MatSnackBar,public UserService:UserService,public branchesService:BranchesService) {

      let users = [];
      users = [{id: 1, role:'Admin', roleId: 455516 ,description: 'Description', status:'Active'},
      {id: 2, role:'Cooperative', roleId: 455516 ,description: 'Description', status:'Active'},
      {id: 3, role:'Employee', roleId: 485516 ,description: 'Description', status:'Active'},
      {id: 4, role:'Accountant', roleId: 455516 ,description: 'Description', status:'Active'}]
  
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(users);
    }

    ngOnInit(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.getBranchList()
    }

    applyFilter(filterValue: string) {
      this.partnerArray.filter = filterValue.trim().toLowerCase();
  
  
    }

    getBranchList() {
      this.UserService.setLoader(true);
      this.branchesService.getBranchList().subscribe(partner => {
      this.partnerArray = partner;
      console.log( this.partnerArray);
      
        this.UserService.setLoader(false);
      },
      (error) => {
        console.log(error)
        this.UserService.setLoader(false);
           this._snackBar.open('Branches not found', 'Ok', {
         horizontalPosition: 'end',
         verticalPosition: 'bottom',
          duration: 5000,
       });
      }
      );
    }






}
