import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BlogsService } from '../../services/blogs.service';

@Component({
    selector: 'app-add-blogs',
    templateUrl: './add-blogs.component.html',
    styleUrls: ['./add-blogs.component.css']
})
export class AddBlogsComponent implements OnInit {
    blogId: number;
    createBlog: FormGroup;
    snackBarDimension: any = {
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        duration: 5000,
    };
    editBlog: any;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    fileToReturn: File;
    imgURL: any;

    constructor(public route: Router, public router: ActivatedRoute, public blogService: BlogsService, public _snackBar: MatSnackBar,
        private modalService: NgbModal,
        ) { }

    ngOnInit(): void {
        this.router.params.subscribe(params => {
            this.blogId = (+params.id);
        });
        if (this.blogId) {
            this.getBlogDetails();
        }
        this.createBlog = new FormGroup({
            'blog': new FormGroup({
                'title': new FormControl(null, [Validators.required]),
                'status': new FormControl(null, [Validators.required]),
                'description': new FormControl(null, [Validators.required]),
                'start_date': new FormControl(null, [Validators.required]),
                'end_date': new FormControl(null, [Validators.required]),
                // 'asset_attributes': new FormGroup({
                //     'asset_file': new FormControl(null, [Validators.required]),
                //     'file_name': new FormControl(null),
                // })
            })
        });
    }

    getBlogDetails() {
        this.blogService.getBlog(this.blogId).subscribe((result: any) => {
            this.editBlog = result;
            this.imgURL = result.asset.asset_expiring_url;
        }), error => {
            this._snackBar.open('Can not get banner details', 'Ok', this.snackBarDimension);
        }
    }

    uploadBlog(event: any, banner): void {
        this.imageChangedEvent = event;
        this.modalService.open(banner, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    base64ToFile(data, filename) {
        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = window.atob(arr[1]);
        let n = bstr.length;
        // let u8arr = new Uint8Array(n);
        let u8arr = new Uint8Array(new ArrayBuffer(n));

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.fileToReturn = this.base64ToFile(
            event.base64,
            this.imageChangedEvent.target.files[0].name,
        );
        if (this.fileToReturn) {
            const file = this.fileToReturn;
            const reader = new FileReader();
            reader.readAsDataURL(this.fileToReturn);
            reader.onload = (_event) => {
                this.imgURL = reader.result;
            };
        }
    }

    imageLoaded(event) {
    }
    cropperReady(event) {
    }
    loadImageFailed(event) {
    }
    resetSelectedImage(element) {
        this.imgURL = '';
    }

    onSubmit(form) {
        const formData = new FormData();
        formData.append('blog[title]', form.blog.title);
        formData.append('blog[status]', form.blog.status);
        formData.append('blog[description]', form.blog.description);
        formData.append('blog[start_date]', form.blog.start_date);
        formData.append('blog[end_date]', form.blog.end_date);
        if (this.fileToReturn) {
            formData.append('blog[asset_attributes][asset_file]', this.fileToReturn);
            formData.append('blog[asset_attributes][file_name]', this.fileToReturn.name);
        }
        if (this.blogId) {
            this.blogService.updateBlog(formData, this.blogId).subscribe(result => {
                this._snackBar.open('blog updated successfully', 'Ok', this.snackBarDimension);
                this.route.navigate(['blogs']);
            }), error => {
                this._snackBar.open('Failed to update. Please try again', 'Ok', this.snackBarDimension);
            }
        } else {
            this.blogService.createBlog(formData).subscribe(result => {
                this._snackBar.open('blog created successfully', 'Ok', this.snackBarDimension);
                this.route.navigate(['blogs']);
            }), error => {
                this._snackBar.open('Failed to create. Please try again', 'Ok', this.snackBarDimension);
            }
        }
    }

    cancel() {
        this.route.navigate(['blogs']);
    }

}
