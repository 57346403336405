import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  ngOnInit(): void {
    this.getUserDetails();
  }

  constructor(
    private userService: UserService,
    public router: Router
    ) { }
  getUserDetails = () => {
    this.userService.getUserDetails().subscribe((projectDetail: any) => {
        console.log("Users",projectDetail)
        this.userService.rolesArrayPermission=projectDetail.perm_resources;
      
      localStorage.setItem('userRole', projectDetail['role']);
      localStorage.setItem('name', projectDetail['name']);
      localStorage.setItem('email', projectDetail['email']);
      localStorage.setItem('companyName', projectDetail.company.name);
      
      localStorage.setItem('cityName', projectDetail.company.city);
      if ((projectDetail['role'] !== 'student' || projectDetail['role'] !== 'demo_student') && projectDetail['staff'] && projectDetail['staff'].id) {
        localStorage.setItem('userId', projectDetail['staff'].id);
      } else if(projectDetail['student'] && projectDetail['student'].id){
        localStorage.setItem('userId', projectDetail['student'].id);
      }
      if(projectDetail['avatar'] && projectDetail['avatar'].asset_expiring_url){
          localStorage.setItem('avatar', projectDetail['avatar'].asset_expiring_url);
      }
    },
    error => {
      if (error.status === 401) {
        localStorage.clear();
        this.router.navigateByUrl('/');
    }
    });
  }
}
