<div class="mdk-drawer-layout__content page">
    <div class="container-fluid page__heading-container">
        <div class="page__heading d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
            <h1 class="m-lg-0">Complaints</h1>
            <button class="btn btn-primary ml-lg-3 float-right"><i class="material-icons mr-2">add_circle</i>Add New</button>

        </div>
    </div>

     <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
      
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
      
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
          </ng-container>
      
          <!-- Progress Column -->
          <ng-container matColumnDef="progress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Progress </th>
            <td mat-cell *matCellDef="let row"> {{row.progress}}% </td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>
      
          <!-- Color Column -->
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
            <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.color}} </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
</div>